import { call, put, takeLatest } from 'redux-saga/effects'
import { webApi } from 'lib/api'
import { errorWrap } from 'lib/saga-helpers'
import { constants } from './actions'

export function * verifyEmail ({ payload: rawUUID }) {
  const { data: { success } } = yield call(webApi.get, `/users/verify-email/${rawUUID}`)

  if (success === true) {
    yield put({
      type: constants.VERIFY_EMAIL_SUCCESS
    })
  } else {
    yield put({
      type: constants.VERIFY_EMAIL_EXPIRED
    })
  }
}

export default function * rootSaga () {
  yield takeLatest(
    constants.VERIFY_EMAIL,
    errorWrap(verifyEmail, constants.VERIFY_EMAIL_FAILED)
  )
}
