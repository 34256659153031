import { Link } from 'react-router-dom'
import * as analytics from 'lib/analytics'
import styles from './styles/NavMenu.css'
import OrganizationLogo from '../misc/OrganizationLogo'

export const NavMenu = ({ items, organizationLogo = null }) => {
  const navClickWrapper = ({
    name,
    onClick = null
  }) => {
    analytics.event({
      category: 'Nav Dropdown',
      action: `Click ${name}`
    })

    if (onClick) {
      onClick()
    }
  }

  return (
    <div className={styles.navMenu}>
      {items
        .map(({ name, href, onClick, itemImg }, i) => {
          const key = name + i
          if (/^http/.test(href)) {
            return (
              <a
                key={key}
                className={styles.item}
                href={href}
                tabIndex={0}
                target='_blank'
                rel='noopener noreferrer'
              >
                {itemImg && (<img className={styles.navItemImg} src={itemImg} />)}
                <span className={styles.navItemCopy}>{name}</span>
              </a>
            )
          }
          if (href) {
            return (
              <Link
                key={key}
                className={styles.item}
                onClick={() => navClickWrapper({ name, onClick })}
                to={href || '#'}
              >
                {itemImg && (<img className={styles.navItemImg} src={itemImg} />)}
                <span className={styles.navItemCopy}>{name}</span>
              </Link>
            )
          }

          return (
            <a
              key={key}
              className={styles.item}
              onClick={() => navClickWrapper({ name, onClick })}
            >
              {itemImg && (<img className={styles.navItemImg} src={itemImg} />)}
              <span className={styles.navItemCopy}>{name}</span>
            </a>
          )
        })}
      {organizationLogo && (
        <OrganizationLogo
          slug={organizationLogo}
          styles={styles.orgLogo}
        />
      )}
    </div>
  )
}

export default NavMenu
