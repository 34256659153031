// getSessionStorage if it exists and we are allowed to access it
export const getSessionStorage = () => {
  if (typeof window === 'undefined') {
    return null
  }
  try {
    const { sessionStorage } = window
    // test if we can access sessionStorage
    sessionStorage.setItem('test', 'test')
    sessionStorage.removeItem('test')
    return sessionStorage
  } catch (e) {
    console.error(e)
    return null
  }
}
