import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import useMeasure from 'react-use-measure'
import { updateDimensions } from 'store/ui/actions'

const Measure = ({ name, children }) => {
  const dispatch = useDispatch()
  const [ref, dimensions] = useMeasure()

  useEffect(() => {
    dispatch(updateDimensions(name, dimensions))
  }, [dimensions])

  return (
    <div ref={ref}>
      {children}
    </div>
  )
}

Measure.propTypes = {
  name: PropTypes.string.isRequired
}

export default Measure
