import { createSelector } from 'reselect'
import { RETRACTION_STATUS_TO_FILTER } from 'store/search/statics'

const MAX_RESULTS = 10000

const isEmpty = o => Object.keys(o).length === 0

export const selectCounts = createSelector(
  state => state.aggregationFilters.authors?.length || 0,
  state => state.author,

  state => state.years,

  state => state.sections,

  state => state.citationTypes,

  state => state.citationRanges,
  state => state.hasTally,

  state => state.journal,
  state => state.affiliation,

  state => state.aggregationFilters.affiliations?.length || 0,

  state => state.aggregationFilters.topics?.length || 0,

  state => state.aggregationFilters.substances?.length || 0,

  state => state.aggregationFilters.paperTypes?.length || 0,

  state => state.aggregationFilters.journals?.length || 0,

  state => Object.values(RETRACTION_STATUS_TO_FILTER)
    .map(key => state.aggregationFilters[key])
    .filter(val => val).length || 0,
  state => state.meshType,
  (
    authorsCount,
    author,
    years,
    sections,
    citationTypes,
    citationRanges,
    hasTally,
    journal,
    affiliation,
    affiliationsCount,
    topicsCount,
    substancesCount,
    paperTypesCount,
    journalsCount,
    noticesCount,
    meshType
  ) => ({
    authors: authorsCount + (author ? 1 : 0),
    year: (years.from ? 1 : 0) + (years.to ? 1 : 0),
    sections: sections.length || 0,
    types: citationTypes.length,
    citations: (
      (isEmpty(citationRanges.supporting) ? 0 : 1) +
      (isEmpty(citationRanges.contrasting) ? 0 : 1) +
      (isEmpty(citationRanges.mentioning) ? 0 : 1) +
      (typeof hasTally === 'boolean' ? 1 : 0)
    ),
    journal: (journal ? 1 : 0) + journalsCount,
    affiliations: (affiliation ? 1 : 0) + affiliationsCount,
    topics: topicsCount,
    mesh: (meshType ? 1 : 0) + (substancesCount || 0),
    substances: substancesCount,
    notices: noticesCount,
    publications: paperTypesCount
  })
)

export const selectHasFilters = createSelector(
  selectCounts,
  counts => Object
    .values(counts)
    .reduce((acc, count) => acc + count, 0) > 0
)

export const selectPageCount = createSelector(
  state => state.resultCount,
  state => state.maxResultsPerPage,
  (resultCount, maxResultsPerPage) =>
    Math.min(Math.ceil(resultCount / maxResultsPerPage), Math.floor(MAX_RESULTS / maxResultsPerPage))
)

const _createSelectAggs = (key, bannedKeys = ['&NA;', 'et al. et al.']) => createSelector(
  state => state.aggregations[key] || [],
  state => state.aggregationFilters[key] || [],
  (aggs, selected) => {
    const filtered = aggs
      .filter(({ key }) => !!key && !bannedKeys.includes(key))

    return [
      ...filtered,
      // add default values so folks can deselect an empty selected agg key.
      ...(
        selected.filter(
          opt => !filtered.map(({ key }) => key).includes(opt)
        ).map(opt => ({ key: opt, docCount: 0 })) || [])
    ]
  }
)

export const aggsSelectors = {
  authors: _createSelectAggs('authors'),
  journals: _createSelectAggs('journals'),
  affiliations: _createSelectAggs('affiliations'),
  topics: _createSelectAggs('topics'),
  substances: _createSelectAggs('substances')
}

export const selectRetractionAggs = createSelector(
  state => state.aggregations.editorialNotices,
  editorialNotices => Object.keys(RETRACTION_STATUS_TO_FILTER).map(filter => ({
    key: filter,
    docCount: editorialNotices?.find(({ key }) => key === filter)?.docCount || 0
  }))
)

export const fullSelectDescriptorOpts = createSelector(
  state => state.search.aggregations.meshDescriptors || [],
  state => state.ui.search.autocompletes['mesh_types.descriptor_name'] || [],
  (aggregations, completions) => {
    const aggregationOpts = aggregations.map(({ key: descriptorId, metadata: { descriptorName } }) => ({
      value: descriptorId,
      label: descriptorName
    }))
    const completionOpts = completions.map(({ data, completion }) => ({
      value: data?.mesh_types.find(t => t.descriptor_name === completion)?.descriptor_id || completion,
      label: completion
    }))

    const seenOpt = {}
    const opts = []

    for (const opt of aggregationOpts.concat(completionOpts)) {
      if (seenOpt[opt.value]) {
        continue
      }

      opts.push(opt)
      seenOpt[opt.value] = true
    }

    return opts
  }
)

//
// Currently we only show search pills in this specific
// scenario, otherwise the standard way of setting/viewing
// search filters is used, see: https://github.com/scitedotai/scite/issues/4919
//
export const selectSearchPills = createSelector(
  state => state?.author,
  author => author ? [{ stateKey: 'author', label: 'Author', value: author }] : []
)

export const selectMinMaxYear = createSelector(
  state => state.aggregations.minDate,
  state => state.aggregations.maxDate,
  (minDateStr, maxDateStr) => {
    if (!minDateStr || !maxDateStr) {
      return null
    }

    const dates = [minDateStr, maxDateStr].map(s => new Date(s))
    const years = dates.map(d => d.getUTCFullYear())
    return {
      min: years[0],
      max: years[1]
    }
  }
)
