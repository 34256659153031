import createActionConstants from 'lib/create-action-constants'

export const constants = createActionConstants('addReportDashboardModal', [
  'SET_SELECTED_PAPERS',
  'CLEAR_SELECTED_PAPERS',

  'FLUSH_ADD_REPORT_DASHBOARD_MODAL'
])

export const setSelectedPapers = payload => ({
  type: constants.SET_SELECTED_PAPERS,
  payload
})

export const clearSelectedPapers = () => ({
  type: constants.CLEAR_SELECTED_PAPERS
})

export const flushAddReportDashboardModal = () => ({
  type: constants.FLUSH_ADD_REPORT_DASHBOARD_MODAL
})
