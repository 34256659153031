import { createStore as reduxCreateStore, applyMiddleware, compose, combineReducers } from 'redux'
import createSagaMiddleware from 'redux-saga'
import createDebounce from 'redux-debounced'
import { staticReducers, staticSagas } from 'client/store'
import createQueryStringMiddleware from 'client/middlewares/query-string'

function createReducer (asyncReducers) {
  return combineReducers({
    ...staticReducers,
    ...asyncReducers
  })
}

function createSagaInjector (runSaga) {
  const injectedSagas = {}

  const injectSaga = (key, saga) => {
    if (injectedSagas[key]) return injectedSagas[key]

    const task = runSaga(saga)
    injectedSagas[key] = task
    return task
  }

  return injectSaga
}

export const createStore = (initialState = {}, history, extraReducers = {}) => {
  const sagaMiddleware = createSagaMiddleware()
  const debounceMiddleware = createDebounce()

  const middleware = [sagaMiddleware, debounceMiddleware]

  if (typeof window !== 'undefined') {
    middleware.push(createQueryStringMiddleware({ history }))
  }

  const devToolsEnhancer = process.env.NODE_ENV === 'development' &&
        typeof window !== 'undefined' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true })
  const composeEnhancers = devToolsEnhancer || compose
  const store = reduxCreateStore(createReducer(extraReducers), initialState, composeEnhancers(
    applyMiddleware(...middleware)
  ))

  //
  // Add a dictionary to keep track of the registered async reducers
  //
  store.asyncReducers = extraReducers

  //
  // Create an inject reducer function
  // This function adds the async reducer, and creates a new combined reducer
  //
  store.injectReducers = (asyncReducers) => {
    Object.keys(asyncReducers).forEach(key => {
      if (!store.asyncReducers[key]) {
        store.asyncReducers[key] = asyncReducers[key]
      }
    })
    store.replaceReducer(createReducer(store.asyncReducers))
  }

  store.injectSaga = createSagaInjector(sagaMiddleware.run)

  const rootTask = store.injectSaga('root', staticSagas)
  const asyncTasks = Object.entries(extraReducers)
    .filter(([_, storeSlice]) => storeSlice.saga)
    .map(([key, storeSlice]) => store.injectSaga(key, storeSlice.saga))

  return { store, sagaTasks: [rootTask, ...asyncTasks] }
}

export default createStore
