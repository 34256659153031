import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './styles/NavAvatar.css'
import Button from '../forms/Button'

export const NavAvatar = ({ src, bg = 'gray', ...rest }) => {
  const classes = {
    defaultIcon: classNames(styles.userIconWrapper, {
      [styles.whiteBg]: bg === 'white'
    })
  }

  return (
    <div className={classes.defaultIcon} {...rest}>
      <i className={styles.downCaret} />
      <Button className={styles.avatarBtn}>
        <img alt='avatar' className={styles.image} src={src || `${CONFIG.assetsBaseURL}/images/icons/person_info.svg`} />
      </Button>
    </div>
  )
}

NavAvatar.propTypes = {
  src: PropTypes.string,
  bg: PropTypes.oneOf([
    'gray',
    'white'
  ])
}

export default NavAvatar
