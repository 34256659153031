import { all, put } from 'redux-saga/effects'
import { setPageError } from 'store/ui/actions'

import addReportDashboardModal from './add-report-dashboard-modal'
import verifyEmail from './verify-email'
import ui from './ui'
import dashboard from './dashboard'
import notifications from './notifications'

//
// These reducers are not included in the static
// set so we need to add manually for tests.
//
export const ASYNC_REDUCERS = [
  'assistant',
  'report',
  'visualizer',
  'blog',
  'admin',
  'classify',
  'authors',
  'home',
  'search',
  {
    key: 'referenceCheck',
    path: 'reference-check'
  },
  {
    key: 'resetPasswordModal',
    path: 'reset-password-modal'
  },
  {
    key: 'dashboardTable',
    path: 'dashboard-table'
  },
  {
    key: 'userProfile',
    path: 'user-profile'
  },
  {
    key: 'organizationLicenses',
    path: 'organization-licenses'
  },
  {
    key: 'dashboardArticles',
    path: 'dashboard-articles'
  },
  {
    key: 'loginModal',
    path: 'login-modal'
  }
]

export const staticReducers = {
  addReportDashboardModal,
  ui,
  verifyEmail,
  dashboard,
  notifications
}

export function * staticSagas () {
  const sagas = Object.values(staticReducers).filter(reducer => reducer.saga).map(reducer => reducer.saga)

  try {
    yield all(sagas.map(saga => saga()))
  } catch (e) {
    console.error(e)
    if (window.Rollbar) {
      Rollbar.error('Uncaught saga error', e)
    }
    yield put(setPageError({ status: 500 }))
  }
}

export default {
  staticReducers,
  staticSagas
}
