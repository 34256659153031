import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './styles/CircleSpinner.css'

export const CircleSpinner = ({ color, size, progress, className, style }) => {
  const circles = [...Array(4)].map((_, index) => {
    return (
      <div
        key={index}
        className={styles.ringComponent}
        style={{
          borderColor: `${color} transparent transparent transparent`,
          width: size * 0.8,
          height: size * 0.8,
          margin: size * 0.1,
          borderWidth: size * 0.1
        }}
      />
    )
  })

  return (
    <div data-testid='circle-spinner' className={classNames(styles.sciteLoadingRing, className)} style={{ width: size, height: size, ...style }}>
      {circles}
      {progress && <span className={styles.percent}>{progress}</span>}
    </div>
  )
}

CircleSpinner.propTypes = {
  color: PropTypes.string, // Hex
  size: PropTypes.number, // Pixels
  className: PropTypes.string,
  progress: PropTypes.string,
  style: PropTypes.object
}

CircleSpinner.defaultProps = {
  color: '#0062ff',
  size: 80,
  className: '',
  style: {}
}

export default CircleSpinner
