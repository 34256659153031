import OpenerIcon from 'components/misc/OpenerIcon'

import { Fade } from 'components/misc/Fade'
import styles from './styles/HoverLink.css'
import Link from '../misc/Link'
import classNames from 'classnames'

const HoverLink = ({
  children,
  to = null,
  open = false,
  includeArrow = false,
  showTriangle = true,
  ...rest
}) => (
  <div
    className={styles.container}
    {...rest}
  >
    <Link
      to={to}
      className={classNames(styles.link, { [styles.linkActive]: open })}
      role='button'
    >
      {children}
      {includeArrow && (
        <>
          <OpenerIcon className={styles.icon} show={open} />
          {showTriangle && <Fade className={styles.arrow} show={open} />}
        </>
      )}
    </Link>
  </div>
)

export default HoverLink
