const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
]

/**
 * dateString converts a timeString that is a unix timestamp to a date
 * to a human readable format.
 */
const dateString = (timeString) => {
  if (!timeString) {
    return ''
  }
  const time = new Date(timeString)
  const month = time.getUTCMonth()
  const day = time.getUTCDate()
  const year = time.getUTCFullYear()
  return `${monthNames[month]} ${day}, ${year}`
}

export default dateString
