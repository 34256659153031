import { ToastContentContainer } from 'client/components/misc/ToastContentContainer'
import toast from 'react-hot-toast'

import { generateRandomString } from 'lib/util'

/**
 *
 * @param {Object} data The API call you want to make
 * @param {function} data.apiCallFn The API call you want to make
 * @param {string} data.loadingToastMessage The message to show for loading toast
 * @param {Object} [data.loadingToastStyle] JSON object of styling info for loading toast
 * @param {number} [data.loadingToastDuration = 100000] Duration of loading toast before it disappears (ms)

 * @param {function} [data.onSuccessFn] Callback function when API call succeeds
 * @param {function} [data.generateSuccessToastMessageFn] Function to generate success toast message. API response is passed in.
 * @param {function} data.successToastActionFn Function that gets called when someone clicks the "action" button of the success toast.
 * @param {Object} [data.successToastStyle] JSON object of styling info for success toast
 * @param {number} [data.successToastDuration = 5500] Duration of loading toast before it disappears (ms)

 * @param {function} [data.generateToastErrorActionLabelFn] Optional function that takes in err and returns a label to use to render the action label in an error toast
 * @param {function} [data.handleToastErrorActionFn] Optional function that takes in err and performs an action if someone clicks on the action label from the error toast
 * @param {function} [data.showToastErrorActionFn] Optional function to decide whether to show an action label on error toasts. err is passed in for conditional handling
 * @param {function} [data.onErrorFn] Callback function when API call errors out
 * @param {function} [data.generateErrorToastMessageFn] Function to generate error toast message. The err from the API call is passed in.
 * @param {Object} [data.errorToastStyle] JSON object of styling info for error toast
 * @param {number} [data.errorToastDuration = 7500] Duration of loading toast before it disappears (ms)
 */
export const apiCallWithToasts = async ({
  apiCallFn,

  loadingToastMessage,
  loadingToastStyle = {
    color: 'black',
    background: 'white',
    width: '80%',
    maxWidth: '40rem'
  },
  loadingToastDuration = 100000,

  onSuccessFn = () => {},
  generateSuccessToastMessageFn = () => {},
  successToastActionFn,
  successToastStyle = {
    color: 'white',
    background: '#339941',
    width: '80%',
    maxWidth: '40rem'
  },
  successToastDuration = 5500,

  generateToastErrorActionLabelFn = () => {},
  handleToastErrorActionFn = () => {},
  onErrorFn = () => {},
  generateErrorToastMessageFn = () => {},
  errorToastStyle = {
    color: 'white',
    background: '#D84215',
    width: '80%',
    maxWidth: '40rem'
  },
  errorToastDuration = 7500
}) => {
  const toastID = generateRandomString()

  const result = await toast.promise(
    apiCallFn(),
    {
      loading: (
        <ToastContentContainer
          toastID={`${toastID}:loading`}
          mainText={loadingToastMessage}
        />
      ),
      success: (data) => {
        if (onSuccessFn) {
          onSuccessFn(data)
        }
        toast.dismiss(`${toastID}:loading`)
        const successToastMessage = generateSuccessToastMessageFn ? generateSuccessToastMessageFn(data) : 'Success!'
        return (
          <ToastContentContainer
            toastID={`${toastID}:success`}
            mainText={successToastMessage}
            actionLabel={successToastActionFn ? 'View' : null}
            actionHandler={() => {
              if (successToastActionFn) {
                successToastActionFn(data)
              }
              toast.dismiss(`${toastID}:success`)
            }}
          />
        )
      },
      error: (err) => {
        if (onErrorFn) {
          onErrorFn()
        }
        const errorToastMessage = generateErrorToastMessageFn ? generateErrorToastMessageFn(err) : 'Uh-oh, something unexpected happened...'
        toast.dismiss(`${toastID}:loading`)
        const errorToastActionLabel = generateToastErrorActionLabelFn(err) || null

        return (
          <ToastContentContainer
            toastID={`${toastID}:error`}
            mainText={errorToastMessage}
            actionLabel={errorToastActionLabel}
            actionHandler={() => {
              if (handleToastErrorActionFn) {
                handleToastErrorActionFn(err)
              }
              toast.dismiss(`${toastID}:error`)
            }}
          />
        )
      }
    },
    {
      loading: {
        duration: loadingToastDuration,
        id: `${toastID}:loading`,
        style: loadingToastStyle
      },
      error: {
        duration: errorToastDuration,
        id: `${toastID}:error`,
        style: errorToastStyle
      },
      success: {
        duration: successToastDuration,
        id: `${toastID}:success`,
        style: successToastStyle
      }
    }
  )
  return result
}

export const infoToast = ({ message, durationMs }) => {
  const toastId = toast(
    (t) => (
      <span>
        {message}
      </span>
    ), {
      duration: durationMs,

      style: {
        minWidth: '850px',
        color: 'black',
        background: '#F4F4F4'
      }
    })
  return toastId
}

export const warnToast = ({ message, durationMs }) => {
  const toastId = toast(
    (t) => (
      <span>
        {message}
      </span>
    ), {
      duration: durationMs,

      style: {
        minWidth: '850px',
        color: 'black',
        background: '#f1c40f'
      }
    })
  return toastId
}

export const successToast = ({ message, durationMs, minWidth = '850px' }) => {
  const toastId = toast.success(
    (t) => (
      <span>
        {message}
      </span>
    ), {
      duration: durationMs,

      style: {
        minWidth,
        color: 'white',
        background: '#339941'
      }
    })
  return toastId
}

export const errorToast = ({ message, durationMs }) => {
  const toastId = toast.error(
    (t) => (
      <span>
        {message}
      </span>
    ), {
      duration: durationMs,

      style: {
        minWidth: '850px',
        color: 'white',
        background: '#D84215'
      }
    })
  return toastId
}
