export const get = (o, key) => {
  if (typeof key !== 'string') {
    return
  }

  const splitKey = key.split('.')
  let val = o
  while (splitKey.length > 0) {
    const nextKey = splitKey.shift()
    val = val?.[nextKey]
  }

  return val
}

export default get
