import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import Feature from './Feature'
import Dropdown from './Dropdown'
import commonStyles from './styles/DropdownCommon.css'
import classNames from 'classnames'
import styles from './styles/FeatureDropdown.css'

export const FeatureDropdown = ({ showDropdownTriangle = true }) => {
  const location = useLocation()
  const [open, setOpen] = useState(false)

  return (
    <Dropdown
      open={open}
      setOpen={setOpen}
      title='Product'
      className={styles.dropdownContainer}
      showTriangle={showDropdownTriangle}
    >
      <div className={styles.grid}>
        <div className={commonStyles.dropdownGroup}>
          <span className={classNames(commonStyles.dropdownCaption, styles.groupTitle)}>
            Features
          </span>

          <Feature
            to='/assistant'
            img={`${CONFIG.assetsBaseURL}/images/icons/assistant.svg`}
            imgClass={styles.illoCompact}
            header='Assistant'
            onClick={() => {
              setOpen(false)
            }}
            className={classNames(styles.featureLink, {
              [styles.disabled]: location.pathname.includes('/assistant')
            })}
            underline={false}
          >
            Your AI-powered research partner.
          </Feature>

          <Feature
            to='/search/landing'
            img={`${CONFIG.assetsBaseURL}/images/icons/feature-search.svg`}
            imgClass={styles.illoCompact}
            header='Search'
            onClick={() => {
              setOpen(false)
            }}
            className={classNames(styles.featureLink, {
              [styles.disabled]: location.pathname.includes('/search/landing')
            })}
            underline={false}
          >
            Search Citation Statements directly for relevant facts and insights.
          </Feature>
          <Feature
            to='/users/me/dashboards'
            img={`${CONFIG.assetsBaseURL}/images/icons/feature-dashboards.svg`}
            imgClass={styles.illoCompact}
            header='Dashboards'
            onClick={() => {
              setOpen(false)
            }}
            className={classNames(styles.featureLink, {
              [styles.disabled]: location.pathname.includes('/dashboards')
            })}
            underline={false}
          >
            Track trends and get insights from groups of papers.
          </Feature>
          <Feature
            to='/?referenceCheck'
            img={`${CONFIG.assetsBaseURL}/images/icons/feature-page.svg`}
            imgClass={styles.illoCompact}
            header='Reference Check'
            onClick={() => {
              setOpen(false)
            }}
            className={classNames(styles.featureLink, {
              [styles.disabled]: location.pathname.includes('/?referenceCheck')
            })}
            underline={false}
          >
            Screen manuscripts as you write to ensure you're using high quality references.
          </Feature>
        </div>

        <div className={commonStyles.dropdownGroup}>
          <span className={classNames(commonStyles.dropdownCaption, styles.groupTitle)}>
            Free Resources
          </span>

          <Feature
            to='/extension-install'
            img={`${CONFIG.assetsBaseURL}/images/icons/browser_extension_icon.svg`}
            imgClass={styles.illoReg}
            header='Browser Extension'
            onClick={() => {
              setOpen(false)
            }}
            className={classNames(styles.featureLink, {
              [styles.disabled]: location.pathname.includes('/extension-install')
            })}
            underline={false}
          >
            Take the power of scite wherever you read research online.
          </Feature>
          <Feature
            to='/badge'
            img={`${CONFIG.assetsBaseURL}/images/icons/smart_cite_icons.svg`}
            imgClass={styles.illoCompact}
            header='Badge'
            onClick={() => {
              setOpen(false)
            }}
            className={classNames(styles.featureLink, {
              [styles.disabled]: location.pathname.includes('/badge')
            })}
            underline={false}
          >
            Embed the free scite Citation or Section Badge into your website.
          </Feature>
          <Feature
            href='https://scite.ai/blog/introducing-the-scite-plug-in-for-zotero-61189d66120c'
            img={`${CONFIG.assetsBaseURL}/images/icons/zotero_plugin_icon.svg`}
            imgClass={styles.illoReg}
            header='Zotero Plugin'
            onClick={() => {
              setOpen(false)
            }}
            className={classNames(styles.featureLink, {
              [styles.disabled]: location.pathname.includes('/blog/introducing-the-scite-plug-in-for-zotero')
            })}
            underline={false}
          >
            Get scite Smart Citation data in your Zotero library.
          </Feature>
        </div>
      </div>
    </Dropdown>
  )
}

export default FeatureDropdown
