import { useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'
import Button from 'components/forms/Button'
import P from 'components/typography/Paragraph'
import Link from 'components/misc/Link'
import Fade from 'components/misc/Fade'
import { loadCookiePreference, toggleModal } from 'store/ui/actions'

import styles from './styles/CookieNotice.css'
import { getLocalStorage } from '../../lib/get-local-storage'

export const CookieNotice = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const cookiePreference = useSelector(({ ui }) => ui.cookiePreference)
  const modalRef = useRef(null)

  const query = qs.parse(location.search)
  const suppressNotice = query.bannerClosed === 'true'

  const setCookiePreference = cookiePreference => {
    const localStorage = getLocalStorage()
    localStorage?.setItem('cookiePreference', cookiePreference)
    dispatch(loadCookiePreference())
  }

  useEffect(() => {
    const trapFocus = (event) => {
      const focusableElements = modalRef?.current?.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      )
      const firstElement = focusableElements[0]
      const lastElement = focusableElements[focusableElements.length - 1]

      if (event.key === 'Tab') {
        if (event.shiftKey) {
          if (document.activeElement === firstElement) {
            event.preventDefault()
            lastElement.focus()
          }
        } else {
          if (document.activeElement === lastElement) {
            event.preventDefault()
            firstElement.focus()
          }
        }
      }
    }

    if (cookiePreference === 'unknown' && !suppressNotice) {
      modalRef?.current?.addEventListener('keydown', trapFocus)
      modalRef?.current?.querySelector('[href]').focus()
    }

    return () => {
      if (modalRef?.current) {
        modalRef?.current?.removeEventListener('keydown', trapFocus)
      }
    }
  }, [cookiePreference, suppressNotice])

  return (
    <Fade className={styles.notice} show={cookiePreference === 'unknown' && !suppressNotice}>
      <div
        role='dialog'
        aria-labelledby='CookieNotice'
        aria-hidden={cookiePreference === 'accepted' && suppressNotice}
        ref={modalRef}
      >
        <P>
          By clicking “Allow All”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts (including targeted advertising).
        </P>
        <P>
          For more information see our <Link underline to='/policy'>Privacy Policy</Link>
        </P>
        <div>
          <Button className={styles.btn} onClick={() => dispatch(toggleModal('cookie'))}>
            Customize Settings
          </Button>
          <Button className={styles.btn} onClick={() => setCookiePreference('rejected')}>
            Reject All Unnecessary Cookies
          </Button>
          <Button className={styles.btn} color='white' onClick={() => setCookiePreference('accepted')}>
            Allow All
          </Button>
        </div>
      </div>
    </Fade>
  )
}

export default CookieNotice
