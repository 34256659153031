const RULES = [
  {
    regex: /U\.S\./i,
    replace: 'U.S.'
  },
  {
    regex: /N\.I\.H\./i,
    replace: 'N.I.H.'
  },
  {
    regex: /P\.H\.S\./i,
    replace: 'P.H.S.'
  },

  {
    regex: /\s(The|A|Or|And)\s/,
    replace: v => v.toLowerCase()
  },

  //
  // Matches roman numerals
  //
  {
    regex: /(^|\s)((?=[mdclxvi])m*(c[md]|d?c{0,3})(x[cl]|l?x{0,3})(i[xv]|v?i{0,3}))\.?(\s+|$)/i,
    replace: v => v.toUpperCase()
  }
]

export default function toTitleCase (str) {
  if (!str) {
    return ''
  }

  let result = str.replace(
    /\w\S*/g,
    txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  )

  for (const rule of RULES) {
    result = result.replace(rule.regex, rule.replace)
  }
  return result
}
