import { call, put } from 'redux-saga/effects'
import { setPageError } from 'store/ui/actions'

export const errorWrap = (saga, FAIL_ACTION_CONSTANT, onError) => function * (...args) {
  try {
    yield call(saga, ...args)
  } catch (e) {
    console.error(e)

    if (typeof Rollbar !== 'undefined') {
      Rollbar.error('Saga error', e)
    }

    if (FAIL_ACTION_CONSTANT) {
      yield put({
        type: FAIL_ACTION_CONSTANT,
        error: e
      })
    }

    if (onError) {
      yield call(onError, e, ...args)
    }
  }
}

//
// Forward data loading errors to React app
// (e.g. if we received 404 or 500 when loading a resource)
//
export const forwardPageError = (forwardList = [404]) => function * (e) {
  const statusCode = e?.response?.status
  if (forwardList === '*' && statusCode >= 400) {
    yield put(setPageError({ status: statusCode }))
  } else if (forwardList.includes(statusCode)) {
    yield put(setPageError({ status: statusCode }))
  }
}
