import PropTypes from 'prop-types'

import styles from './styles/SocialIcon.css'
import classNames from 'classnames'
import Link from './Link'

const SOCIAL = {
  twitter: {
    href: 'https://twitter.com/scite',
    icon: 'icon-twitter-new'
  },
  linkedin: {
    href: 'https://www.linkedin.com/company/sciteai/',
    icon: 'icon-linkedin-new'
  },
  facebook: {
    href: 'https://facebook.com/sciteai',
    icon: 'icon-facebook-new'
  },
  instagram: {
    href: 'https://www.instagram.com/scite.ai/',
    icon: 'icon-instagram-new'
  },
  youtube: {
    href: 'https://www.youtube.com/channel/UCso9-KdHZ6vfbYgM59zp9EA',
    icon: 'icon-youtube-new'
  },
  tiktok: {
    href: 'https://www.tiktok.com/@scite.ai',
    icon: 'icon-tiktok'
  }
}

function SocialIcon ({ type, href, className, ...rest }) {
  return (
    <Link className={classNames(styles.socialIcon, className)} href={href || SOCIAL[type].href} target='_blank' rel='noopener noreferrer' {...rest} aria-label={SOCIAL[type].href}>
      <i className={classNames(styles.socialLogo, 'icon', SOCIAL[type].icon)} />
    </Link>
  )
}

SocialIcon.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  type: PropTypes.oneOf(['facebook', 'twitter', 'linkedin', 'instagram', 'youtube', 'tiktok'])
}
export default SocialIcon
