import createActionConstants from 'lib/create-action-constants'

export const constants = createActionConstants('verify-email', [
  'VERIFY_EMAIL',
  'VERIFY_EMAIL_SUCCESS',
  'VERIFY_EMAIL_EXPIRED',
  'VERIFY_EMAIL_FAILED'
])

export const verifyEmail = (rawUUID) => ({
  type: constants.VERIFY_EMAIL,
  payload: rawUUID
})

export const verifyEmailExpired = () => ({
  type: constants.VERIFY_EMAIL_EXPIRED
})

export const verifyEmailSuccess = () => ({
  type: constants.VERIFY_EMAIL_SUCCESS
})
