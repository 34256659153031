import { call, delay, put, fork, takeLatest } from 'redux-saga/effects'
import { api } from 'lib/api'
import { apiCallWithToasts } from 'lib/toast'
import Link from 'components/misc/Link'
import { constants } from './actions'
import { errorWrap, forwardPageError } from 'lib/saga-helpers'
import { DASHBOARD_TYPE_URL_PREFIX_MAP } from 'lib/helpers/dashboard'

export function * fetchDashboard ({ payload: { slug, type, headers } }) {
  const enabled = CONFIG.featureFlags.dashboardsEnabled[type]
  if (enabled !== undefined && !enabled) {
    return
  }

  const { data } = yield call(
    api.get,
    `${DASHBOARD_TYPE_URL_PREFIX_MAP[type]}/${slug}`,
    { headers }
  )
  yield put({
    type: constants.FETCH_DASHBOARD_SUCCESS,
    payload: { slug, type, data }
  })
}

export function * deleteCustomDashboard ({
  payload: { dashboardSlug, userSlug, history }
}) {
  yield call(api.delete, `/dashboards/${dashboardSlug}`)
  yield put({
    type: constants.DELETE_CUSTOM_DASHBOARD_SUCCESS
  })
  history.push(`/users/${userSlug}/dashboards`)
}

function * waitForDashboardExport ({ dashboardSlug, dashboardType, format, columns }) {
  const {
    data: { exportId }
  } = yield call(
    api.post,
    `${DASHBOARD_TYPE_URL_PREFIX_MAP[dashboardType]}/${dashboardSlug}/export`,
    { format, columns }
  )

  while (true) {
    const {
      data: { status }
    } = yield call(
      api.get,
      `${DASHBOARD_TYPE_URL_PREFIX_MAP[dashboardType]}/${dashboardSlug}/export/${exportId}/status?format=${format}`
    )
    if (['PENDING', 'IN_PROGRESS'].includes(status)) {
      yield delay(2000)
      continue
    }

    if (status === 'ERROR') {
      throw new Error('Could not export dashboard CSV')
    }

    return `${CONFIG.apiURL}${DASHBOARD_TYPE_URL_PREFIX_MAP[dashboardType]}/${dashboardSlug}/export/${exportId}?format=${format}`
  }
}

export function * exportDashboard ({ payload: opts }) {
  const task = yield fork(waitForDashboardExport, opts)
  const { format } = opts

  yield call(apiCallWithToasts, {
    apiCallFn: () => task.toPromise(),
    loadingToastMessage: `Exporting dashboard data as ${format}`,
    generateSuccessToastMessageFn: (url) => {
      return (
        <span>
          Success!{' '}
          <Link underline href={url} target='_blank' rel='noreferrer'>
            Download file
          </Link>
        </span>
      )
    },
    generateErrorToastMessageFn: (_) => 'Error exporting data',
    successToastDuration: 30000
  })

  yield put({
    type: constants.EXPORT_DASHBOARD_SUCCESS
  })
}

function * setDashboardUsers ({ payload: { dashboardSlug, user } }) {
  yield call(api.post, `/dashboards/${dashboardSlug}/grant-access`, { ...user })

  yield put({
    type: constants.SET_DASHBOARD_USERS_SUCCESS
  })
}

function * getDashboardAccessList ({ payload: { dashboardSlug } }) {
  const accessList = yield call(api.get, `/dashboards/${dashboardSlug}/access-list`)

  yield put({
    type: constants.GET_DASHBOARD_ACCESS_LIST_SUCCESS,
    payload: accessList.data
  })
}

export default function * rootSaga () {
  yield takeLatest(
    constants.DELETE_CUSTOM_DASHBOARD,
    errorWrap(deleteCustomDashboard, constants.DELETE_CUSTOM_DASHBOARD_FAILURE)
  )
  yield takeLatest(
    constants.FETCH_DASHBOARD,
    errorWrap(fetchDashboard, constants.FETCH_DASHBOARD_FAILURE, forwardPageError('*'))
  )
  yield takeLatest(
    constants.EXPORT_DASHBOARD,
    errorWrap(exportDashboard, constants.EXPORT_DASHBOARD_FAILURE)
  )
  yield takeLatest(
    constants.GET_DASHBOARD_ACCESS_LIST,
    errorWrap(getDashboardAccessList, constants.GET_DASHBOARD_ACCESS_LIST_FAILURE)
  )
  yield takeLatest(
    constants.SET_DASHBOARD_USERS,
    errorWrap(setDashboardUsers, constants.SET_DASHBOARD_USERS_FAILURE)
  )
}
