import PropTypes from 'prop-types'
import styles from './styles/ToastContentContainer.css'
import toast from 'react-hot-toast'

export const ToastContentContainer = ({
  mainText,
  toastID,
  actionLabel,
  actionHandler
}) => {
  return (
    <span className={styles.toastContainer}>
      <span className={styles.toastText}>{mainText}</span>
      {actionLabel && actionHandler && (
        <span className={styles.toastActionLink} onClick={actionHandler}>
          {actionLabel}
        </span>
      )}
      <span className={styles.toastActionLink} onClick={() => toast.dismiss(toastID)}>Dismiss</span>
    </span>
  )
}

ToastContentContainer.defaultProps = {
  actionLabel: '',
  actionHandler: null
}

ToastContentContainer.propTypes = {
  mainText: PropTypes.string.isRequired,
  toastID: PropTypes.string.isRequired,
  actionLabel: PropTypes.string,
  actionHandler: PropTypes.func
}

export default ToastContentContainer
