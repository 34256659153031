import { setPostHogPersonMetadata, capturePostHogEvent } from 'client/lib/posthog'
import sleep from 'lib/sleep'
import * as rollbar from 'client/lib/rollbar'
import isEqual from 'lodash.isequal'

const IS_DEV = process.env.NODE_ENV === 'development'
const SERVER_SIDE = typeof window === 'undefined'
const DISABLED = (SERVER_SIDE || !CONFIG.googleAnalytics.enabled)

export const setup = async cookiePreference => {
  if (typeof window === 'undefined') {
    return
  }

  const gpcValue = window.navigator.globalPrivacyControl

  if (DISABLED || gpcValue) return

  while (true) {
    if (!window.gtag) {
      await sleep(1000)
      continue
    }

    if (cookiePreference === 'accepted' || cookiePreference.split(',').includes('targeting')) {
      gtag('consent', 'update', {
        ad_storage: 'granted',
        analytics_storage: 'granted'
      })
    }

    gtag('config', CONFIG.googleAnalytics.ga4id)
    break
  }

  if (cookiePreference === 'accepted' || cookiePreference.split(',').includes('targeting')) {
    // set up tiktok pixel
    const tiktok = document.createElement('script')
    tiktok.async = true
    tiktok.innerHTML = `
!function (w, d, t) {
  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
  ttq.load('C2HT4PVMU8QAJ3JEMGQ0');
  ttq.page();
  ttq.track('Browse')
}(window, document, 'ttq');
    `
    document.head.appendChild(tiktok)
  }
}

export const pageView = () => {
  if (DISABLED) return
  if (typeof gtag === 'undefined') {
    return
  }
  gtag('event', 'page_view', {
    page_path: window.location.pathname,
    page_location: String(window.location)
  })
}

export const modalView = (modal) => {
  if (DISABLED) return
  if (typeof gtag === 'undefined') {
    return
  }
  gtag('event', 'page_view', {
    page_path: modal
  })
}

export const SIGNUP_METHODS = {
  'Created an Account': 'local',

  'Logged in - Orcid': 'orcid',
  'Signed up - Orcid': 'orcid',

  'Logged in - Facebook': 'facebook',
  'Signed up - Facebook': 'facebook',

  'Logged in - Google': 'google',
  'Signed up - Google': 'google',

  'Logged in - Saml SSO': 'saml',
  'Signed up - Saml SSO': 'saml'
}

// These indicate a user has an account
// sometimes they will have an account from the past
// and are just signing in which is ok.
export const SIGNUP_ACTIONS = {
  CREATED_ACCOUNT: 'Created an Account',

  LOGGED_IN_ORCID: 'Logged in - Orcid',
  SIGNED_UP_ORCID: 'Signed up - Orcid',

  LOGGED_IN_FACEBOOK: 'Logged in - Facebook',
  SIGNED_UP_FACEBOOK: 'Signed up - Facebook',

  LOGGED_IN_GOOGLE: 'Logged in - Google',
  SIGNED_UP_GOOGLE: 'Signed up - Google',

  LOGGED_IN_SAML_SSO: 'Logged in - Saml SSO',
  SIGNED_UP_SAML_SSO: 'Signed up - Saml SSO'
}

// These are predefined tiktok events found: https://ads.tiktok.com/help/article?aid=10028
const TIKTOK_EVENTS = {
  REGISTRATION: 'Registration',
  CHECKOUT: 'Checkout',
  START_CHECKOUT: 'StartCheckout',
  SUBSCRIBE: 'Subscribe'
}

// These are predefined facebook events found: https://developers.facebook.com/docs/facebook-pixel/reference#standard-events
const FACEBOOK_EVENTS = {
  COMPLETE_REGISTRATION: 'CompleteRegistration',
  SUBSCRIBE: 'Subscribe',
  INITIATE_CHECKOUT: 'InitiateCheckout',
  START_TRIAL: 'StartTrial'
}

function bingReportSubscribeConversion () {
  if (DISABLED) return
  if (typeof window.uetq !== 'undefined') {  // eslint-disable-line
    window.uetq = window.uetq || []  // eslint-disable-line
    window.uetq.push('event', 'subscribe', {})  // eslint-disable-line
  }
}

// pixelTrack will send events to a given ad pixel to build audiences
// in the respective ad platforms.
const pixelTrack = (event) => {
  let gtagType, tiktokType, facebookType
  if (Object.values(SIGNUP_ACTIONS).includes(event.action)) {
    gtagType = CONFIG.gtag.signup
    tiktokType = TIKTOK_EVENTS.REGISTRATION
    facebookType = FACEBOOK_EVENTS.COMPLETE_REGISTRATION
  } else if (event.action === 'Purchased Plan') {
    gtagType = CONFIG.gtag.subscribeToPremium
    bingReportSubscribeConversion()
    tiktokType = TIKTOK_EVENTS.CHECKOUT
    facebookType = FACEBOOK_EVENTS.SUBSCRIBE
  } else if (event.action === 'View Checkout') {
    gtagType = CONFIG.gtag.viewCheckout
    tiktokType = TIKTOK_EVENTS.START_CHECKOUT
    facebookType = FACEBOOK_EVENTS.INITIATE_CHECKOUT
  } else if (event.action === 'View Pricing Page') {
    gtagType = CONFIG.gtag.viewPricingPage
    tiktokType = TIKTOK_EVENTS.START_CHECKOUT
    facebookType = FACEBOOK_EVENTS.INITIATE_CHECKOUT
  } else if (event.action === 'Activated Trial') {
    tiktokType = TIKTOK_EVENTS.SUBSCRIBE
    bingReportSubscribeConversion()
    facebookType = FACEBOOK_EVENTS.START_TRIAL
  } else {
    return
  }
  if (typeof gtag !== 'undefined') { // eslint-disable-line
    gtag('event', 'conversion', { // eslint-disable-line
      send_to: gtagType
    })
  }
  if (typeof ttq !== 'undefined') { // eslint-disable-line
    ttq.track(tiktokType) // eslint-disable-line
  }
  if (typeof fbq !== 'undefined') { // eslint-disable-line
    fbq('track', facebookType) // eslint-disable-line
  }
}

export const event = (event, { pushToPosthog = true } = {}) => {
  const { action } = event

  if (IS_DEV) {
    console.log('GA event:', event)
  }

  if (DISABLED) return

  const gtagEvent = {}
  if (event.category) {
    gtagEvent.event_category = event.category
  }
  if (event.label) {
    gtagEvent.event_label = event.label
  }
  if (event.member_id) {
    gtagEvent.member_id = event.member_id
  }
  if (window.gtag) {
    gtag('event', event.action, gtagEvent)
  }

  pixelTrack(event)

  // add sign up conversion event
  // with oauth we treat logins as sign ups
  // analytics can dedupe these into actual sign ups
  if (Object.values(SIGNUP_ACTIONS).includes(action)) {
    if (window.gtag) {
      gtag('event', 'sign_up', {
        method: SIGNUP_METHODS[action]
      })
    }
  }

  if (pushToPosthog) {
    capturePostHogEvent({
      name: `${event.category}: ${event.action}`,
      metadata: event
    })
  }
}

// This is for events that are custom GA hooks like purchasing
export const rawGtag = (...args) => {
  if (DISABLED) return
  if (!window.gtag) {
    return
  }
  gtag(...args)
}

const setUserGA = (user) => {
  if (DISABLED) return
  if (!window.gtag) {
    return
  }
  gtag('set', 'user_properties', {
    plan: user.subscription?.plan || 'free',
    subscriptionStatus: user.subscription?.status || 'inactive',
    orcid: user.orcid,
    signupType: user.signupType,
    persona: user.persona || user.userSpecifiedPersona || null,
    organizationLicense: user.organizationLicense
  })
}

let previouslySetUser = {}

// setUser is a universal analytics function
// that captures setting a user's analytics in all analytics apps
// this is the only function that should be used to set up a user in analytics
export const setUser = (user) => {
  if (isEqual(user, previouslySetUser)) {
    console.debug('User already set')
    return
  }
  previouslySetUser = { ...user }
  setPostHogPersonMetadata(user)
  rollbar.setUser(user)
  setUserGA(user)
  if (typeof profitwell !== 'undefined') {
    profitwell('start', {
      user_email: user.email
    })
  }
}
