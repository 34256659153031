import createReducer from 'lib/create-reducer'
import { constants } from './actions'
import saga from './sagas'

const defaultState = {
  loading: false,
  verified: false,
  expired: false
}

const verifyEmail = createReducer(defaultState, {
  [constants.VERIFY_EMAIL]: state => ({
    ...state,
    loading: true,
    failed: false
  }),
  [constants.VERIFY_EMAIL_SUCCESS]: state => ({
    ...state,
    loading: false,
    verified: true,
    failed: false
  }),
  [constants.VERIFY_EMAIL_EXPIRED]: state => ({
    ...state,
    loading: false,
    expired: true,
    failed: false
  }),
  [constants.VERIFY_EMAIL_FAILED]: state => ({
    ...state,
    loading: false,
    expired: false,
    failed: true
  })
}, saga)

export default verifyEmail
