import { useRef } from 'react'
import { useHistory } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames'

import SearchInput from 'components/misc/SearchInput'
import SearchPills from 'components/search/SearchPills'
import Dropdown from 'components/search/Dropdown'
import { selectMaybeDoi } from 'store/ui/selectors'
import { selectSearchPills } from 'store/search/selectors'
import { setSearchTerm, fetchAutocompletes, flushAutocompletes, setSearchFocused } from 'store/ui/actions'
import { Fade } from 'components/misc/Fade'
import styles from './styles/SearchBox.css'

export const SearchBox = ({
  className,
  inputContainerClass,
  inputClass,
  buttonClass,
  buttonIconClass,
  dropdownClass,
  placeholder,
  dropdown,
  onSubmit,
  inputRef
}) => {
  const ref = useRef()
  const history = useHistory()
  const dispatch = useDispatch()
  const term = useSelector(({ ui }) => ui.search.term)
  const focused = useSelector(({ ui }) => ui.search.focused)
  const maybeDoi = useSelector(({ ui }) => selectMaybeDoi(ui))
  const searchPills = useSelector(({ search }) => selectSearchPills(search))
  const hasSearchPills = !!searchPills.length

  const handleSearchChange = e => {
    const newTerm = e.target.value.trimLeft()
    dispatch(setSearchTerm(newTerm))

    if (newTerm.length > 2) {
      dispatch(fetchAutocompletes(newTerm, 'title'))
    }
  }

  const handleBlur = e => {
    if (e && e.target.dataset.showdropdown && e.target.type !== 'text') {
      return
    }
    dispatch(setSearchFocused(false))
  }

  const handleFocus = () => {
    dispatch(setSearchFocused(true))
  }

  const handleClick = () => dispatch(setSearchFocused(true))

  const handleSubmit = e => {
    e && e.preventDefault()
    document.activeElement.blur()

    dispatch(setSearchFocused(false))

    if (maybeDoi) {
      return history.push(`/reports/${maybeDoi.trim()}`)
    }

    onSubmit && onSubmit(e)
  }

  const handleDropdownClick = val => {
    dispatch(setSearchTerm(val))
    dispatch(flushAutocompletes('term'))
    handleSubmit()
  }

  return (
    <SearchInput
      ref={ref}
      aria-label='search'
      data-showdropdown
      autoComplete='off'
      className={classNames(className, styles.input, {
        [styles.focused]: focused
      })}
      inputContainerClass={inputContainerClass}
      inputClass={inputClass}
      buttonClass={buttonClass}
      buttonIconClass={buttonIconClass}
      type='text'
      value={term}
      placeholder={hasSearchPills ? '' : placeholder}
      onSubmit={handleSubmit}
      onClick={handleClick}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={handleSearchChange}
      inputRef={inputRef}
    >
      <SearchPills />
      <Fade className={styles.dropdowns} show={focused}>
        {dropdown && (
          <Dropdown
            dropdownStyle={dropdownClass}
            field='title'
            parentFocused={focused}
            term={term}
            onClickResult={handleDropdownClick}
            showLinks
          />
        )}
      </Fade>
    </SearchInput>
  )
}

export default SearchBox
