import qs from 'query-string'
import get from 'lib/get'

/**
 * Propagate state changes to query string */
const createQueryStringMiddleware = ({ history }) => store => next => action => {
  if (typeof window === 'undefined') {
    return next(action)
  }

  const queryUpdate = get(action, 'meta.queryUpdate')
  const result = next(action)
  const nextState = store.getState()

  if (!queryUpdate) {
    return result
  }

  const location = queryUpdate.location || window.location
  const { push, update, scrollToRef, prune } = queryUpdate
  const { search } = location
  const resolvedUpdate = Object
    .keys(update)
    .map(key => ({
      [key]: get(nextState, update[key])
    }))
    .reduce((acc, val) => ({ ...acc, ...val }), {})

  const pathChanged = queryUpdate.location && (queryUpdate.location?.pathname !== window.location?.pathname)

  //
  // If the path changed we only want to resolve new items
  // rather than merging with previous ones.
  //
  // We also allow forcing this behavior with prune for
  // scenarios where you would otherwise be resolving
  // most updates to null/undefined.
  //
  const newQuery = (pathChanged || prune)
    ? {
        ...resolvedUpdate
      }
    : {
        ...qs.parse(search, { arrayFormat: 'index' }),
        ...resolvedUpdate
      }

  //
  // Don't allow empty strings
  //
  // Also strip out page=1 to avoid page duplicate
  //
  Object
    .keys(newQuery)
    .forEach(key => {
      if (newQuery[key] === '' || newQuery[key] === null) {
        delete newQuery[key]
      }

      if (key === 'page' && Number(newQuery[key]) === 1) {
        delete newQuery[key]
      }
    })

  const newSearch = qs.stringify(newQuery, { arrayFormat: 'index' })

  if (search === newSearch && !pathChanged) {
    return result
  }

  if (push) {
    history.push({
      ...location,
      search: newSearch
    })

    if (scrollToRef) {
      scrollToRef.current.scrollIntoView()
    } else {
      window.scrollTo(0, 0)
    }
  } else {
    history.replace({
      ...location,
      search: newSearch
    })
  }

  return result
}

export default createQueryStringMiddleware
