import { call, select, put, takeLatest } from 'redux-saga/effects'
import { api } from 'lib/api'
import { errorWrap } from 'lib/saga-helpers'
import { constants } from './actions'
import { errorToast, successToast } from '../../lib/toast'

const selectors = {
  pageNumber: ({ notifications }) => notifications.notificationItemsPage,
  filters: ({ notifications }) => notifications.notificationItemsFilters
}

function * fetchNotifications () {
  const { data } = yield call(api.get, '/notifications/notifications')
  yield put({
    type: constants.FETCH_NOTIFICATIONS_SUCCESS,
    payload: data
  })
}

function * fetchNotificationItems ({ payload }) {
  const pageNumber = yield select(selectors.pageNumber)
  const filters = yield select(selectors.filters)
  const params = {
    offset: (pageNumber - 1 || 0) * CONFIG.notificationItemsPaginationLimit,
    limit: CONFIG.notificationItemsPaginationLimit,
    filters: JSON.stringify(filters || {}),
    order_by_oldest: payload?.sortByOldest || false
  }
  const { data } = yield call(api.get, '/notifications/feed', { params })
  yield put({
    type: constants.FETCH_NOTIFICATION_ITEMS_SUCCESS,
    payload: data
  })
}

function * fetchDropDownNotificationItems () {
  const params = { offset: 0, limit: CONFIG.notificationItemsDropDownPaginationLimit, new_only: true, metadata_only: true }
  const { data } = yield call(api.get, '/notifications/feed', { params })
  yield put({
    type: constants.FETCH_DROP_DOWN_NOTIFICATION_ITEMS_SUCCESS,
    payload: data
  })
}

function * setNotification ({ payload }) {
  const { data } = yield call(api.post, '/notifications/notifications', payload)
  yield put({ type: constants.SET_NOTIFICATION_SUCCESS, payload: { notification: data } })

  successToast({
    message: 'Successfully set notification.',
    durationMs: 3000
  })
}

function * updateNotification ({ payload }) {
  yield call(api.put, '/notifications/notifications', payload)
  yield put({ type: constants.UPDATE_NOTIFICATION_SUCCESS })
}

function * setNotificationItemsStatus ({ payload }) {
  yield call(api.post, '/notifications/feed', payload)
  yield put({ type: constants.SET_NOTIFICATION_ITEMS_STATUS_SUCCESS, payload: { notificationItemStatuses: payload } })
}

function * deleteNotifications ({ payload: ids }) {
  for (const id of ids) {
    yield call(api.delete, `/notifications/notifications/${id}`)
  }
  yield put({ type: constants.DELETE_NOTIFICATIONS_SUCCESS, payload: ids })

  const msg = ids.length > 1 ? 'Successfully removed notifications.' : 'Successfully removed notification.'
  successToast({
    message: msg,
    durationMs: 3000
  })
}

export default function * rootSaga () {
  yield takeLatest(constants.FETCH_NOTIFICATIONS, errorWrap(fetchNotifications, constants.FETCH_NOTIFICATIONS_FAILURE))
  yield takeLatest(
    constants.SET_NOTIFICATION,
    errorWrap(setNotification, null, function * (e, { payload }) {
      errorToast({
        message: 'Unexpected error setting notification.',
        durationMs: 3000
      })
      yield put({
        type: constants.SET_NOTIFICATION_FAILURE,
        error: e,
        payload: {
          error: e.response?.data?.detail || '',
          source: payload.source,
          category: payload.category
        }
      })
    })
  )
  yield takeLatest(
    constants.DELETE_NOTIFICATIONS,
    errorWrap(deleteNotifications, function * (e, { payload }) {
      errorToast({
        message: 'Unexpected error removing notification.',
        durationMs: 3000
      })
      yield put({
        type: constants.DELETE_NOTIFICATIONS_FAILURE
      })
    })
  )
  yield takeLatest(constants.FETCH_NOTIFICATION_ITEMS, errorWrap(fetchNotificationItems, constants.FETCH_NOTIFICATION_ITEMS_FAILURE))
  yield takeLatest(constants.FETCH_DROP_DOWN_NOTIFICATION_ITEMS, errorWrap(fetchDropDownNotificationItems, constants.FETCH_DROP_DOWN_NOTIFICATION_ITEMS_FAILURE))
  yield takeLatest(constants.SET_NOTIFICATION_ITEMS_STATUS, errorWrap(setNotificationItemsStatus, constants.SET_NOTIFICATION_ITEMS_STATUS_FAILURE))
  yield takeLatest(constants.UPDATE_NOTIFICATION, errorWrap(updateNotification, constants.UPDATE_NOTIFICATION_FAILURE))
}
