import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import Feature from './Feature'
import Dropdown from './Dropdown'
import commonStyles from './styles/DropdownCommon.css'
import classNames from 'classnames'
import styles from './styles/ResourceDropdown.css'

export const ResourceDropdown = ({ showDropdownTriangle = true }) => {
  const location = useLocation()
  const [open, setOpen] = useState(false)

  return (
    <Dropdown
      open={open}
      setOpen={setOpen}
      title='Solutions'
      className={styles.dropdownContainer}
      showTriangle={showDropdownTriangle}
    >
      <div className={styles.grid}>
        <div className={commonStyles.dropdownGroup}>
          <span className={classNames(commonStyles.dropdownCaption, styles.groupTitle)}>
            Use Cases
          </span>

          <Feature
            to='/partners/pharma-and-biotech'
            img={`${CONFIG.assetsBaseURL}/images/icons/use_case_pharma_dropdown_icon.svg`}
            header='For Corporate / Pharma'
            onClick={() => {
              setOpen(false)
            }}
            className={classNames(styles.featureLink, {
              [styles.disabled]: location.pathname.includes('/partners/pharmaceuticals-and-biotech')
            })}
            underline={false}
          >
            Stay on top of how your research assets are being used.
          </Feature>
          <Feature
            to='/partners/academic-institutions'
            img={`${CONFIG.assetsBaseURL}/images/icons/use_case_universities_dropdown_icon.svg`}
            header='For Universities & Libraries'
            onClick={() => {
              setOpen(false)
            }}
            className={classNames(styles.featureLink, {
              [styles.disabled]: location.pathname.includes('/partners/academic-institutions')
            })}
            underline={false}
          >
            Provide your researchers with tools to improve their work.
          </Feature>
          <Feature
            to='/partners/researchers'
            img={`${CONFIG.assetsBaseURL}/images/icons/use_case_researchers_dropdown_icon.svg`}
            header='For Researchers'
            onClick={() => {
              setOpen(false)
            }}
            className={classNames(styles.featureLink, {
              [styles.disabled]: location.pathname.includes('/partners/researchers')
            })}
            underline={false}
          >
            Know exactly what you should be citing.
          </Feature>
          <Feature
            to='/partners/publishers'
            img={`${CONFIG.assetsBaseURL}/images/icons/use_case_publishers_dropdown_icon.svg`}
            header='For Publishers'
            onClick={() => {
              setOpen(false)
            }}
            className={classNames(styles.featureLink, {
              [styles.disabled]: location.pathname.includes('/partners/publishers')
            })}
            underline={false}
          >
            Improve your discoverability and better support your authors.
          </Feature>
        </div>

        <div className={commonStyles.dropdownGroup}>
          <span className={classNames(commonStyles.dropdownCaption, styles.groupTitle)}>
            Explore
          </span>

          <Feature
            to='/journals'
            header='Journals'
            img={`${CONFIG.assetsBaseURL}/images/icons/feature-journals.svg`}
            imgClass={styles.illoCompact}
            onClick={() => {
              setOpen(false)
            }}
            className={classNames(styles.featureLink, {
              [styles.disabled]: location.pathname.includes('/journals')
            })}
            underline={false}
          />
          <Feature
            to='/affiliations'
            header='Organizations'
            img={`${CONFIG.assetsBaseURL}/images/icons/organization_icon.svg`}
            imgClass={styles.illoOrg}
            onClick={() => {
              setOpen(false)
            }}
            className={classNames(styles.featureLink, {
              [styles.disabled]: location.pathname.includes('/affiliations')
            })}
            underline={false}
          />
          <Feature
            to='/institutions'
            header='Funders'
            img={`${CONFIG.assetsBaseURL}/images/icons/referrals.svg`}
            imgClass={styles.illoCompact}
            onClick={() => {
              setOpen(false)
            }}
            className={classNames(styles.featureLink, {
              [styles.disabled]: location.pathname.includes('/institutions')
            })}
            underline={false}
          />
        </div>

        <div className={commonStyles.dropdownGroup}>
          <span className={classNames(commonStyles.dropdownCaption, styles.groupTitle)}>
            Resources
          </span>
          <Feature
            to='/request-a-demo'
            header='Request a Demo'
            img={`${CONFIG.assetsBaseURL}/images/icons/request_demo.svg`}
            onClick={() => {
              setOpen(false)
            }}
            className={classNames(styles.featureLink, {
              [styles.disabled]: location.pathname.includes('/request-a-demo')
            })}
            underline={false}
          >
            Learn how scite can improve research outcomes for your org.
          </Feature>
          <Feature
            href='https://direct.mit.edu/qss/article/2/3/882/102990/scite-A-smart-citation-index-that-displays-the'
            header='How scite works'
            img={`${CONFIG.assetsBaseURL}/images/icons/how_scite_works.svg`}
            onClick={() => {
              setOpen(false)
            }}
            className={styles.featureLink}
            underline={false}

          >
            Read about the technical details powering our Smart Citations.
          </Feature>
          <Feature
            href='https://help.researchsolutions.com/hc/en-us/categories/31947397460116-Scite'
            img={`${CONFIG.assetsBaseURL}/images/icons/help_faq.svg`}
            header='Help & FAQ'
            className={styles.featureLink}
            underline={false}
          >
            Get help with scite features to make the most of them for your work.
          </Feature>
        </div>
      </div>
    </Dropdown>
  )
}

export default ResourceDropdown
