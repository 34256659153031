import classNames from 'classnames'

import styles from './styles/Header.css'

export const Header = ({ className, level = 1, children, center, light, ...rest }) => {
  const Tag = `h${level}`
  return (
    <Tag
      className={classNames(styles[Tag], {
        [styles.center]: center,
        [styles.light]: light
      }, className)}
      {...rest}
    >
      {children}
    </Tag>
  )
}

export default (Header)
