/**
 * Download string as file
 * @param {string} str - text to download
 * @param {string} filename - filename to download to
 * @param {string} mimetype - mimetype to download as
 */
export const downloadString = (
  str,
  filename,
  mimetype = 'data:text/plain;charset=utf-8'
) => {
  const blob = new window.Blob([str], { type: mimetype })
  const el = document.createElement('a')
  el.setAttribute('href', window.URL.createObjectURL(blob))
  el.setAttribute('download', filename)
  el.style.display = 'none'
  document.body.appendChild(el)
  el.click()
  document.body.removeChild(el)
}

export default downloadString
