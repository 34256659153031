import createReducer from 'lib/create-reducer'
import { constants } from './actions'

const defaultState = {
  selectedPapers: []
}

const addReportDashboardModal = createReducer(defaultState, {
  [constants.SET_SELECTED_PAPERS]: (state, selectedPapers) => ({
    ...state,
    selectedPapers
  }),
  [constants.CLEAR_SELECTED_PAPERS]: (state) => ({
    ...state,
    selectedPapers: []
  }),
  [constants.FLUSH_ADD_REPORT_DASHBOARD_MODAL]: (state) => ({
    ...defaultState
  })
})

export default addReportDashboardModal
