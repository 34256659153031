import capitalize from 'lodash.capitalize'

export const normalizeLicenseName = (name) => {
  if (!name) {
    return 'Your institution'
  }
  // if name contains '-', capitalize each word
  if (name.includes('-')) {
    return name.split('-').map(capitalize).join(' ')
  }

  return name
}
