import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'

import styles from './styles/Link.css'
import classNames from 'classnames'

export function Link ({ className, href, to, children, bold, blue, underline, tabIndex = 0, onClick, ...rest }) {
  return to
    ? (
      <RouterLink
        className={classNames(className, styles.link, { [styles.blueLink]: blue, [styles.underline]: underline, [styles.bold]: bold }, className)} to={to} onClick={onClick} tabIndex={tabIndex} {...rest}
      >{children}
      </RouterLink>
      )
    : (
      <a
        className={classNames(className, styles.link, { [styles.blueLink]: blue, [styles.underline]: underline, [styles.bold]: bold }, className)}
        href={href}
        onClick={onClick}
        tabIndex={tabIndex}
        role={href ? 'link' : 'button'}
        {...rest}
      >{children}
      </a>
      )
}

Link.propTypes = {
  href: PropTypes.string,
  className: PropTypes.string,
  to: PropTypes.string,
  blue: PropTypes.bool,
  underline: PropTypes.bool,
  bold: PropTypes.bool,
  tabIndex: PropTypes.number
}

export default (Link)
