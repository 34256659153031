import { createSelector } from 'reselect'

export const selectMaybeDoi = createSelector(
  state => state.search.term,
  term => {
    if (/^10\./.test(term)) {
      return term
    }

    // Sometimes they paste in a URL with a DOI at the end
    if (/^http(s)?:\/\/(.+)?\/10\./.test(term)) {
      // NOTE: /^10.\d{4,9}/[-._;()/:A-Z0-9]+$/i matches 74.4M out of 74.9M dois according to crossref
      // (https://www.crossref.org/blog/dois-and-matching-regular-expressions/)
      // TODO: make sure that we grab indeed the DOI at the end, not right of where the first string split happens
      return '10.' + term.split('10.')[1]
    }
  }
)

export const selectLinkResolver = createSelector(
  state => state.linkResolver,
  state => state.hasUser,
  state => state.fetchingUser,
  state => state.user,
  (linkResolver, hasUser, fetchingUser, user) => {
    if (linkResolver) {
      return linkResolver
    }
    return null
  }
)
