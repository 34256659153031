// getLocalStorage if it exists and we are allowed to access it
export const getLocalStorage = () => {
  if (typeof window === 'undefined') {
    return null
  }
  try {
    const { localStorage } = window
    // test if we can access localStorage
    localStorage.setItem('test', 'test')
    localStorage.removeItem('test')
    return localStorage
  } catch (e) {
    console.error(e)
    return null
  }
}
