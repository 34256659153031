import createReducer from 'lib/create-reducer'
import { queryStringBool } from '../../lib/helpers/search'
import { constants } from './actions'
import saga from './sagas'

const defaultState = {
  notifications: [],
  loading: false,
  error: null,

  notificationItems: [],
  notificationItemsLoading: false,
  notificationItemsError: null,
  notificationItemsPage: 1,
  notificationItemsTotal: 0,
  newNotificationItems: 0,
  notificationItemsFilters: {},

  dropDownNotificationItems: [],
  dropDownNotificationItemsLoading: false,
  dropDownNotificationItemsError: null,

  notificationSetStatusLoading: false,
  notificationSetStatusError: null
}

const userProfile = createReducer(defaultState, {
  [constants.FETCH_NOTIFICATIONS]: state => ({
    ...state,
    loading: true
  }),
  [constants.FETCH_NOTIFICATIONS_SUCCESS]: (state, notifications) => ({
    ...state,
    loading: false,
    notifications
  }),
  [constants.FETCH_NOTIFICATIONS_FAILURE]: state => ({
    ...state,
    loading: false
  }),
  [constants.SET_NOTIFICATION]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [constants.SET_NOTIFICATION_SUCCESS]: (state, { notification }) => ({
    ...state,
    loading: false,
    notifications: [...state.notifications, notification]
  }),
  [constants.SET_NOTIFICATION_FAILURE]: (state, payload) => ({
    ...state,
    loading: false,
    error: payload
  }),
  [constants.UPDATE_NOTIFICATION]: (state, notification) => ({
    ...state,
    error: null,
    notifications: state.notifications.map(item => item.id === notification.id ? notification : item)
  }),
  [constants.UPDATE_NOTIFICATION_SUCCESS]: (state) => ({
    ...state,
    loading: false
  }),
  [constants.UPDATE_NOTIFICATION_FAILURE]: (state, payload) => ({
    ...state,
    loading: false,
    error: payload
  }),
  [constants.FLUSH_NOTIFICATION_ERROR]: (state) => ({
    ...state,
    error: null
  }),
  [constants.DELETE_NOTIFICATIONS]: state => ({
    ...state,
    loading: true
  }),
  [constants.DELETE_NOTIFICATIONS_SUCCESS]: (state, ids) => ({
    ...state,
    loading: false,
    notifications: state.notifications?.filter(({ id }) => !ids.includes(id))
  }),
  [constants.DELETE_NOTIFICATIONS_FAILURE]: state => ({
    ...state,
    loading: false
  }),
  [constants.FETCH_NOTIFICATION_ITEMS]: (state, payload) => {
    // we want to totally reset filters only with the {} payload
    let notificationItemsFilters = {
      ...state.notificationItemsFilters,
      ...payload?.filters
    }
    if (payload?.filters && Object.keys(payload?.filters).length === 0) {
      notificationItemsFilters = {}
    }
    return {
      ...state,
      notificationItemsLoading: true,
      notificationItemsPage: payload?.pageNumber || state.notificationItemsPage,
      notificationItemsFilters
    }
  },
  [constants.FETCH_NOTIFICATION_ITEMS_SUCCESS]: (state, { notificationItems, total }) => ({
    ...state,
    notificationItemsLoading: false,
    notificationItems: notificationItems,
    notificationItemsTotal: total
  }),
  [constants.FETCH_NOTIFICATION_ITEMS_FAILURE]: state => ({
    ...state,
    notificationItemsLoading: false
  }),
  [constants.FETCH_DROP_DOWN_NOTIFICATION_ITEMS]: (state) => ({
    ...state,
    dropDownNotificationItemsLoading: true
  }),
  [constants.FETCH_DROP_DOWN_NOTIFICATION_ITEMS_SUCCESS]: (state, { notificationItems, newNotifications }) => ({
    ...state,
    dropDownNotificationItemsLoading: false,
    dropDownNotificationItems: notificationItems,
    newNotificationItems: newNotifications
  }),
  [constants.FETCH_DROP_DOWN_NOTIFICATION_ITEMS_FAILURE]: state => ({
    ...state,
    dropDownNotificationItemsLoading: false
  }),
  [constants.SET_NOTIFICATION_ITEMS_STATUS]: (state, payload) => {
    const newNewCount = Math.max(
      0,
      state.newNotificationItems - (payload?.filter(item => item.status !== 'NEW').length || 0)
    )
    return {
      ...state,
      notificationSetStatusLoading: true,
      notificationSetStatusError: null,
      newNotificationItems: newNewCount
    }
  },
  [constants.SET_NOTIFICATION_ITEMS_STATUS_SUCCESS]: (state, { notificationItemStatuses }) => {
    // replace state.notificationItems with notificationItem status when notificationID matches
    const notificationItems = state.notificationItems.map(notificationItem => {
      const notificationItemStatus = notificationItemStatuses.find(({ notificationID }) => notificationID === notificationItem.notificationID)
      if (notificationItemStatus) {
        return {
          ...notificationItem,
          ...notificationItemStatus
        }
      }
      return notificationItem
    })

    const dropDownNotificationItems = state.dropDownNotificationItems.map(notificationItem => {
      const notificationItemStatus = notificationItemStatuses.find(({ notificationID }) => notificationID === notificationItem.notificationID)
      if (notificationItemStatus) {
        return {
          ...notificationItem,
          ...notificationItemStatus
        }
      }
      return notificationItem
    })

    return {
      ...state,
      notificationSetStatusLoading: false,
      notificationItems,
      dropDownNotificationItems
    }
  },
  [constants.SET_NOTIFICATION_ITEMS_STATUS_FAILURE]: (state, payload) => ({
    ...state,
    notificationSetStatusLoading: false,
    notificationSetStatusError: payload
  }),
  [constants.HANDLE_QUERY_CHANGE]: (state, query) => (
    {
      ...state,
      notificationItemsPage: Number(query.page) || 1,
      notificationItemsFilters: {
        ...state.notificationItemsFilters,
        notificationIDs: query?.notificationIDs?.map(item => Number(item)) || [],
        time: {
          startDate: query?.startDate || null,
          endDate: query?.endDate || null
        },
        status: {
          read: queryStringBool(query, 'read') || false,
          new: queryStringBool(query, 'new') || false,
          archived: queryStringBool(query, 'archived', false) || false
        },
        notificationType: {
          citationStatements: queryStringBool(query, 'citationStatements') || false,
          newPublications: queryStringBool(query, 'newPublications') || false
        }
      }
    }
  )
}, saga)

export default userProfile
