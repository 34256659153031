import PropTypes from 'prop-types'
import { useTransition, animated } from 'react-spring'

const DIRECTIONS = {
  up: 'translate3d(0,-120%,0)',
  down: 'translate3d(0,120%,0)',
  left: 'translate3d(-120%,0,0)',
  right: 'translate3d(120%,0,0)'
}

const DIRECTIONS_ENTER = {
  up: 'translate3d(0,0%,0)',
  down: 'translate3d(0,0%,0)',
  left: 'translate3d(0%,0,0)',
  right: 'translate3d(0%,0,0)'
}
export const Slide = ({ direction, show, children, className, springConfig, style = {}, ...rest }) => {
  const transitions = useTransition(show, {
    from: { transform: DIRECTIONS[direction] },
    enter: { transform: DIRECTIONS_ENTER[direction] },
    leave: { transform: DIRECTIONS[direction] },
    initial: null
  })
  return transitions((props, show) => show && (
    <animated.div className={className} style={{ ...style, ...props }} {...rest}>
      {children}
    </animated.div>
  ))
}

Slide.defaultProps = {
  direction: 'up'
}

Slide.propTypes = {
  show: PropTypes.bool.isRequired,
  direction: PropTypes.oneOf([
    'up',
    'down',
    'left',
    'right'
  ]),
  className: PropTypes.string,
  springConfig: PropTypes.object,
  style: PropTypes.object
}

export default Slide
