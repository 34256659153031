import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './styles/SearchInput.css'

export const SearchInput = forwardRef(({
  children,
  className,
  inputClass,
  buttonClass,
  buttonIconClass,
  placeholder,
  value,
  onSubmit,
  onChange,
  onClick,
  onBlur,
  onFocus,
  inputRef,
  label = 'search'
}, ref) => {
  return (
    <form className={classNames(styles.searchInput, className)} onSubmit={onSubmit} ref={ref}>
      {children}

      <input
        ref={inputRef}
        aria-label={label}
        data-showdropdown
        autoComplete='off'
        className={classNames(styles.input, inputClass)}
        type='text'
        value={value}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        onClick={onClick}
      />
      <div className={classNames(styles.button, buttonClass)} aria-label='search-button' role='button' tabIndex='0' onClick={onSubmit}>
        <i className={classNames('icon', 'icon-search', styles.icon, buttonIconClass)} />
      </div>
    </form>
  )
})

SearchInput.propTypes = {
  className: PropTypes.string,
  inputContainerClass: PropTypes.string,
  inputClass: PropTypes.string,
  buttonClass: PropTypes.string,
  buttonIconClass: PropTypes.string,
  placeholder: PropTypes.string,
  dropdown: PropTypes.bool,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object
  ])
}

SearchInput.defaultProps = {
  placeholder: 'Search by title, author, keywords, or DOI',
  dropdown: true
}

export default SearchInput
