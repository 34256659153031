export const initialize = async cookiePreference => {
  if (typeof window === 'undefined' || !cookiePreference) {
    return
  }

  const gpcValue = window.navigator.globalPrivacyControl

  if (gpcValue) return

  if (cookiePreference === 'accepted' || cookiePreference.split(',').includes('targeting')) {
    const script = document.createElement('script')
    script.innerHTML = `
        (function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');
    `
    document.head.appendChild(script)
    const script2 = document.createElement('script')
    script2.async = true
    script2.dataset.rewardful = 'd89eb8'
    script2.src = 'https://r.wdfl.co/rw.js'
    document.head.appendChild(script2)
  }
}
