import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './styles/Paragraph.css'

export const Paragraph = ({ className = '', type = 'body', bold = false, italic = false, medium = false, light = false, left = false, center = false, small = false, children, ...rest }) => {
  return (
    <p
      className={classNames(className, styles[type], {
        [styles.bold]: bold,
        [styles.italic]: italic,
        [styles.medium]: medium,
        [styles.small]: small,
        [styles.light]: light,
        [styles.center]: center
      })}
      {...rest}
    >
      {children}
    </p>
  )
}

Paragraph.propTypes = {
  type: PropTypes.oneOf([
    'body',
    'small',
    'bodyLeft'
  ])
}

export default Paragraph
