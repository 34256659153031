
export const DASHBOARD_TYPE_URL_PREFIX_MAP = {
  custom: '/dashboards',
  journal: '/dashboards/journal',
  institution: '/dashboards/funding-institution',
  affiliation: '/dashboards/institution'
}

export const getSearchLink = ({ slug, dashboardType, doisUpdatedTS }) => {
  const keyMap = {
    custom: 'dashboardSlug',
    affiliation: 'affiliations[0]',
    journal: 'journals[0]'
  }
  const key = keyMap[dashboardType]
  if (!key) {
    return null
  }

  const value = dashboardType === 'journal' ? slug.split('-').slice(0, -1).join(' ') : slug
  let link = `/search?${key}=${value}`
  if (dashboardType === 'custom') {
    link += `&dashboardDOIsUpdatedTs=${doisUpdatedTS}`
  }
  return link
}
