import { useState } from 'react'
import Button from '../forms/Button'
import OpenerIcon from '../misc/OpenerIcon'
import Fade from '../misc/Fade'
import { Header } from '../typography/Header'
import Paragraph from '../typography/Paragraph'
import styles from './styles/RSProductsBar.css'

const assets = CONFIG.assetsBaseURL

const RSProductsBar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const products = [
    {
      title: 'Article Galaxy',
      text: 'Instant access to the any research article in the world. Streamline your research process with fast, reliable document delivery.'
    },
    {
      title: 'Article Galaxy References',
      text: 'Supplement your collections with instant access to any article. Organize your references efficiently with advanced management tools.'
    },
    {
      title: 'Scite',
      text: 'Your AI research partner. Analyze citations and discover new research connections with AI-driven technology.'
    }
  ]

  const features = [
    {
      icon: `${assets}/images/icons/rs-products-bar/journal.svg`,
      text: 'Get any Research Document'
    },
    {
      icon: `${assets}/images/icons/rs-products-bar/search.svg`,
      text: 'Citation Search'
    },
    {
      icon: `${assets}/images/icons/rs-products-bar/aiasisstant.svg`,
      text: 'AI Research Assistant'
    },
    {
      icon: `${assets}/images/icons/rs-products-bar/entitlementmanagement.svg`,
      text: 'Entitlement Management'
    },
    {
      icon: `${assets}/images/icons/rs-products-bar/copyright.svg`,
      text: 'Re-use & Copytight Management'
    },
    {
      icon: `${assets}/images/icons/rs-products-bar/admincontrol.svg`,
      text: 'Admin & Cost Controls'
    },
    {
      icon: `${assets}/images/icons/rs-products-bar/literaturemg.svg`,
      text: 'Literature Management'
    },
    {
      icon: `${assets}/images/icons/rs-products-bar/citationtrack.svg`,
      text: 'Citation Tracking'
    }
  ]

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  const onDropdownBlur = () => {
    if (isDropdownOpen) {
      setIsDropdownOpen(false)
    }
  }

  const handleClickExplorePlatform = () => {
    window.open('https://www.researchsolutions.com/', '_blank')
  }

  return (
    <>
      <div className={styles.bar}>
        <div className={styles.logo}>
          <img
            alt='research solutions logo'
            src={`${assets}/images/research-solutions-blue-sm.png`}
            height={42}
            className={styles.img}
          />
        </div>

        <div className={styles.label}>

          <Button
            className={styles.dropdownButton}
            onClick={toggleDropdown}
            onBlur={onDropdownBlur}
          >
            Explore the Research Solutions product family
            <OpenerIcon show={isDropdownOpen} />
          </Button>
        </div>
      </div>

      <Fade show={isDropdownOpen} className={styles.dropdownContainer}>
        <div className={styles.dropdownContent}>
          <div className={styles.column}>
            <Header level={3} className={styles.mainTitle}>
              Products
            </Header>

            <Paragraph light className={styles.productsParagraph}>
              Explore our enterprise platform with a wide range of products for all your research needs all seamlessly integrated for a all-in-one solution.
            </Paragraph>

            <Button
              color='blue'
              className={styles.buttonExplore}
              onMouseDown={handleClickExplorePlatform}
            >
              Explore the platform
            </Button>
          </div>

          <div className={styles.column}>
            {products.map((item, index) => (
              <div key={index.toString()} className={styles.productSection}>
                <Header level={5} className={styles.productTitle}>
                  {item.title}
                </Header>

                <Paragraph light className={styles.productDescription}>
                  {item.text}
                </Paragraph>
              </div>
            ))}
          </div>

          <div className={styles.column}>
            {features.map((item, index) => (
              <div className={styles.featureContainer} key={index.toString()}>
                <img src={item.icon} className={styles.featureImage} />

                <Paragraph medium className={styles.featureText}>
                  {item.text}
                </Paragraph>
              </div>
            ))}
          </div>
        </div>
      </Fade>
    </>
  )
}

export default RSProductsBar
