import { useState, useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import Button from 'components/forms/Button'
import NavDropdown from 'components/navbar/NavDropdown'
import { setMode } from 'store/login-modal/actions'
import { toggleModal, logout } from 'store/ui/actions'
import styles from './styles/NavLogin.css'
import HoverLink from './HoverLink'
import NavAvatar from '../misc/NavAvatar'
import CircleSpinner from '../misc/CircleSpinner'
import * as zendesk from '../../lib/zendesk'

const NavLogin = ({ avatarBg = 'gray', buttonColor = 'transparent', showSignup }) => {
  const user = useSelector(({ ui: { user } }) => user)
  const fetchingUser = useSelector(({ ui: { fetchingUser } }) => fetchingUser)
  const extensionLoaded = useSelector(({ ui }) => ui.extensionLoaded)
  const browser = useSelector(({ ui }) => ui.browser)

  const dispatch = useDispatch()
  const [hasToken, setHasToken] = useState(null)
  const [supportChatCopy, setSupportChatCopy] = useState('Hide Support Chat')

  const SUPPORTED_BROWSERS = [
    'chrome',
    'firefox',
    'safari'
  ]

  const links = {
    chrome: CONFIG.plugin.chromeLink,
    firefox: CONFIG.plugin.firefoxLink,
    safari: CONFIG.plugin.safariLink
  }

  const browserName = SUPPORTED_BROWSERS.includes(browser?.name) ? browser?.name : 'chrome'

  const toggleSupportChat = () => {
    if (!window || !window.zE) return

    const isWidgetVisible = zendesk.getChatStatus()
    let newSupportCopy

    if (!isWidgetVisible) {
      zendesk.showWidget()
      zendesk.openChat()
      newSupportCopy = 'Hide Support Chat'
    } else {
      zendesk.closeChat()
      zendesk.hideWidget()
      newSupportCopy = 'Chat with Support'
    }

    setTimeout(() => setSupportChatCopy(newSupportCopy), 500)
  }

  useEffect(() => {
    const token = window?.localStorage?.getItem('apiToken') || null
    setHasToken(!!token)
  }, [])

  useEffect(() => {
    user?.email && getZendeskStatus() ? zendesk.showWidget() : zendesk.hideWidget()
    const initialSupportCopy = getZendeskStatus() ? 'Hide Support Chat' : 'Chat with Support'
    setSupportChatCopy(initialSupportCopy)
  }, [user])

  const removeZendeskWidget = () => {
    zendesk.hideWidget()
    setTimeout(() => zendesk.resetChatStatus(), 500)
  }

  const getZendeskStatus = () => {
    return zendesk.getChatStatus()
  }

  const setAvatarItems = () => {
    const items = [
      {
        name: (
          <>
            <span className={styles.navUserName}>{user.name}</span>
            <span className={styles.navUserEmail}>{user.email}</span>
          </>
        ),
        href: ''
      },
      {
        name: 'My Profile',
        itemImg: `${CONFIG.assetsBaseURL}/images/icons/person_info.svg`,
        href: `/users/${user.slug}`
      },
      {
        name: 'Tools',
        itemImg: `${CONFIG.assetsBaseURL}/images/icons/tool_box_icon.svg`,
        href: `/users/${user.slug}/dashboards`
      },
      {
        name: 'Account Settings',
        itemImg: `${CONFIG.assetsBaseURL}/images/icons/badge.svg`,
        href: `/users/${user.slug}/account-settings`
      },
      {
        name: 'Subscription',
        itemImg: `${CONFIG.assetsBaseURL}/images/icons/subscription.svg`,
        href: `/users/${user.slug}/subscription`
      },
      {
        name: 'Pricing',
        itemImg: `${CONFIG.assetsBaseURL}/images/icons/pricing_icon.svg`,
        href: '/pricing'
      },
      {
        name: (
          <>
            {(user?.subscription?.plan === 'free' || user?.subscription?.status === 'trialing')
              ? (
                <span className={styles.referralsPromotion}>Get 40% off!</span>
                )
              : (
                  'Invite Others'
                )}
          </>
        ),
        itemImg: `${CONFIG.assetsBaseURL}/images/icons/invite_others_icon.svg`,
        href: `/users/${user.slug}/referrals`
      },
      {
        name: 'Tutorials',
        itemImg: `${CONFIG.assetsBaseURL}/images/icons/get_help_icon.svg`,
        href: `/users/${user.slug}/help`
      },
      {
        name: supportChatCopy,
        itemImg: `${CONFIG.assetsBaseURL}/images/icons/chat-icon.svg`,
        onClick: toggleSupportChat
      },
      {
        name: 'Sign Out',
        itemImg: `${CONFIG.assetsBaseURL}/images/icons/sign_out_icon.svg`,
        onClick: () => {
          dispatch(logout())
          removeZendeskWidget()
        },
        href: '#'
      }
    ]

    const installExtension = {
      item: {
        name: 'Install Extension',
        itemImg: `${CONFIG.assetsBaseURL}/images/icons/${browserName}.svg`,
        href: links[browserName]
      },
      index: 2
    }

    return (!fetchingUser && !extensionLoaded)
      ? [
          ...items.slice(0, installExtension.index),
          installExtension.item,
          ...items.slice(installExtension.index)
        ]
      : items
  }

  const avatarItems = user
    ? setAvatarItems()
    : []

  const handleSignin = () => {
    dispatch(setMode('login'))
    dispatch(toggleModal('login'))
  }

  const handleSignup = () => {
    dispatch(setMode('register'))
    dispatch(toggleModal('login'))
  }

  //
  // Initial render
  //
  if (hasToken === null) {
    return null
  }

  //
  // Show loading indicator if fetching user
  //
  if (fetchingUser) {
    return <CircleSpinner size={25} />
  }
  if (user?.email) {
    return (
      <NavDropdown dropdownClassName={styles.avatarWrapper} items={avatarItems} menuOffset={[-45, 12]}>
        <NavAvatar src={user.avatar} bg={avatarBg} />
      </NavDropdown>
    )
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      handleSignup()
    }
  }

  return (
    showSignup
      ? (
        <div className={styles.buttonLayout}>
          <HoverLink id='login-nav-link' onClick={handleSignin}>Log In</HoverLink>
          <Button
            className={styles.button}
            onClick={handleSignup}
            color={buttonColor}
            bold
            small
            onKeyDown={handleKeyDown}
          >
            Sign Up
          </Button>
        </div>
        )
      : (
        <div className={styles.buttonLayout}>
          <Button
            className={styles.button}
            onClick={handleSignin}
            color={buttonColor}
            bold
            small
          >
            Log in
          </Button>
        </div>
        )
  )
}

export default NavLogin
