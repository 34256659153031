import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import dateString from 'lib/date-string'
import classNames from 'classnames'

import { toggleModal } from '../../store/ui/actions'
import { setMode } from '../../store/login-modal/actions'

import styles from './styles/TopBanner.css'
import { normalizeLicenseName } from '../../lib/organization-license-name'
import { getLocalStorage } from '../../lib/get-local-storage'

const useOrgLogo = (slug) => {
  const [organizationLogo, setOrganizationLogo] = useState(null)

  useEffect(() => {
    const imageUrl = `${CONFIG.assetsBaseURL}/images/organization-license-logos/${slug}.png`
    const image = new Image() // eslint-disable-line
    image.src = imageUrl
    image.onerror = () => setOrganizationLogo(null)
    image.onload = () => setOrganizationLogo(imageUrl)
  }, [slug])

  return organizationLogo
}

const licenseMessage = (license) => {
  const institutionName = normalizeLicenseName(license?.name)
  if (license?.status === 'TRIALING' || license?.status === 'TRIAL_READY') {
    if (license?.trialEndDate) {
      return `${institutionName} is trialing scite until ${dateString(license?.trialEndDate)}.`
    } else {
      return `${institutionName} is trialing scite.`
    }
  }
  return `${institutionName} subscribes to an organization-wide license of scite.`
}

export const TopBanner = () => {
  const STORAGE_KEY = 'license-banner'
  const license = useSelector(({ ui }) => ui.organizationLicense)
  const dispatch = useDispatch()
  const organizationLogo = useOrgLogo(license?.slug)
  const localStorage = getLocalStorage()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(localStorage?.getItem(STORAGE_KEY) !== 'closed' && license?.hasLicense)
  }, [license?.hasLicense])

  if (!open) return null

  const showLogin = () => {
    dispatch(toggleModal('login'))
    dispatch(setMode('login'))
  }

  const showRegister = () => {
    dispatch(toggleModal('login'))
    dispatch(setMode('register'))
  }

  const handleClose = () => {
    localStorage?.setItem(STORAGE_KEY, 'closed')
    setOpen(false)
  }

  return (
    <div className={styles.topBar}>
      <div className={classNames({
        [styles.topBarContent]: true,
        [styles.hasImage]: !!organizationLogo
      })}
      >
        {organizationLogo && (
          <div className={styles.image}>
            <img
              src={organizationLogo}
              alt='Organization Logo'
            />
          </div>
        )}
        <div className={styles.greeting}>
          <h5>Welcome!</h5>
          <p>{licenseMessage(license)}</p>
          <div className={styles.closeBanner} onClick={handleClose}>
            <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z' fill='#1C1B1F' />
            </svg>
          </div>
        </div>
        <div className={styles.note}>
          You are not required to <a href='#' onClick={showRegister}>create an account</a> or <a href='#' onClick={showLogin}>log in</a> to search scite. However, an account is needed to access many scite features (e.g. notifications, Assistant history, and dashboards).
        </div>
      </div>
    </div>
  )
}
