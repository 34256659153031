export const MODES = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'Citation Statements',
    value: 'citations'
  },
  {
    label: 'Papers',
    value: 'papers'
  },
  {
    label: 'scite Assistant',
    value: 'assistant'
  }
]

export const SORT_OPTIONS = [
  {
    value: {},
    label: 'Relevance'
  },
  {
    label: 'Date published (recent)',
    value: { key: 'date', order: 'desc' }
  },
  {
    label: 'Date published (oldest)',
    value: { key: 'date', order: 'asc' }
  },
  {
    label: 'Most cited',
    value: { key: 'total_cited', order: 'desc' }
  },
  {
    label: 'Least cited',
    value: { key: 'total_cited', order: 'asc' }
  },
  {
    label: 'Most supported',
    value: { key: 'total_supported', order: 'desc' }
  },
  {
    label: 'Least supported',
    value: { key: 'total_supported', order: 'asc' }
  },
  {
    label: 'Most contrasted',
    value: { key: 'total_contrasted', order: 'desc' }
  },
  {
    label: 'Least contrasted',
    value: { key: 'total_contrasted', order: 'asc' }
  }
]

export const RETRACTION_STATUS_TO_FILTER = {
  retracted: 'hasRetraction',
  'has expression of concern': 'hasConcern',
  'has correction': 'hasCorrection',
  'has erratum': 'hasErratum',
  withdrawn: 'hasWithdrawn'
}

export const PAPER_TYPE_FILTER = {
  Book: 'bookFilter',
  Article: 'articleFilter',
  Preprint: 'preprintFilter',
  'Other Source Types': 'otherPaperTypeFilter'
}

export const CITATION_TYPE_FILTER = {
  'Has Cites': 'hasCites'
}
