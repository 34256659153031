import styles from './styles/OpenerIcon.css'

const OpenerIcon = ({ show, className }) => {
  return (
    <div className={`${styles.folder} ${show && styles.folderDown} ${className}`}>
      <i className={`${styles.angleDown}`} />
    </div>
  )
}

export default OpenerIcon
