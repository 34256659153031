import createActionConstants from 'lib/create-action-constants'

export const constants = createActionConstants('dashboard', [
  'FETCH_DASHBOARD',
  'FETCH_DASHBOARD_SUCCESS',
  'FETCH_DASHBOARD_FAILURE',

  'FLUSH_DASHBOARD',

  'EDIT_CUSTOM_DASHBOARD',
  'EDIT_CUSTOM_DASHBOARD_SUCCESS',
  'EDIT_CUSTOM_DASHBOARD_FAILURE',

  'DELETE_CUSTOM_DASHBOARD',
  'DELETE_CUSTOM_DASHBOARD_SUCCESS',
  'DELETE_CUSTOM_DASHBOARD_FAILURE',

  'EXPORT_DASHBOARD',
  'EXPORT_DASHBOARD_SUCCESS',
  'EXPORT_DASHBOARD_FAILURE',

  'SET_DASHBOARD_USERS',
  'SET_DASHBOARD_USERS_SUCCESS',
  'SET_DASHBOARD_USERS_FAILURE',

  'GET_DASHBOARD_ACCESS_LIST',
  'GET_DASHBOARD_ACCESS_LIST_SUCCESS',
  'GET_DASHBOARD_ACCESS_LIST_FAILURE',

  'CLEAR_SET_DASHBOARD_STATE'
])

export const fetchDashboard = (slug, type, headers) => ({
  type: constants.FETCH_DASHBOARD,
  payload: {
    slug,
    type,
    headers
  }
})

export const flushDashboard = () => ({
  type: constants.FLUSH_DASHBOARD
})

export const deleteCustomDashboard = (dashboardSlug, userSlug, history) => ({
  type: constants.DELETE_CUSTOM_DASHBOARD,
  payload: {
    dashboardSlug,
    userSlug,
    history
  }
})

export const exportDashboard = opts => ({
  type: constants.EXPORT_DASHBOARD,
  payload: opts
})

export const setDashboardUsers = (payload) => ({
  type: constants.SET_DASHBOARD_USERS,
  payload
})

export const clearSetDashboardState = () => ({
  type: constants.CLEAR_SET_DASHBOARD_STATE
})

export const getDashboardAccessList = (dashboardSlug) => ({
  type: constants.GET_DASHBOARD_ACCESS_LIST,
  payload: {
    dashboardSlug
  }
})
