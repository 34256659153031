import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './styles/Button.css'

export function Button ({
  className,
  children,
  color = 'transparent',
  pill,
  group,
  row,
  bold,
  small,
  tiny,
  rounded = false,
  size = 'medium',
  square = false,
  circle = false,
  ...rest
}) {
  const noBorder = color === 'secondary' && (rounded || square || circle)

  const classes = classNames(
    className,
    styles.btn,
    styles[`btn--${color}`],
    styles[`size--${size}`], {
      [styles.pill]: pill,
      [styles.group]: group,
      [styles.row]: row,
      [styles.bold]: bold,
      [styles.small]: small,
      [styles.tiny]: tiny,
      [styles.rounded]: rounded,
      [styles.noBorder]: noBorder,
      [styles[`square--${size}`]]: square,
      [styles[`circle--${size}`]]: circle
    }
  )

  return (
    <button className={classes} {...rest}>
      {children}
    </button>
  )
}

Button.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary', 'text', 'blank', 'red', 'blue', 'white', 'transparent']),
  size: PropTypes.oneOf(['small', 'medium', 'large', 'extraLarge']),
  pill: PropTypes.bool,
  group: PropTypes.bool,
  row: PropTypes.bool,
  bold: PropTypes.bool,
  small: PropTypes.bool,
  tiny: PropTypes.bool,
  rounded: PropTypes.bool,
  square: PropTypes.bool,
  circle: PropTypes.bool
}

export default Button
