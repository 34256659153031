import { PLANS } from '../../server/lib/constants'

export const planIsAtLeast = (userPlan, atLeastPlan) => {
  if (userPlan === PLANS.premium) {
    return [PLANS.premium, PLANS.free].includes(atLeastPlan)
  }
  if (userPlan === PLANS.free) {
    return [PLANS.free].includes(atLeastPlan)
  }

  return false
}

export const userIsAtLeast = (user, atLeastPlan) => {
  if (!user) {
    return false
  }

  if (user.admin) {
    return true
  }

  if (atLeastPlan === PLANS.organizational) {
    return !!user.organizationLicense
  }

  return planIsAtLeast(user.subscription?.plan, atLeastPlan)
}
