import { useRef } from 'react'

import { Fade } from 'components/misc/Fade'
import useClickOut from 'hooks/useClickOut'
import styles from './styles/Dropdown.css'
import classNames from 'classnames'
import HoverLink from './HoverLink'

export const Dropdown = ({
  open,
  setOpen,
  title,
  children,
  className = undefined,
  showTriangle = true
}) => {
  const ref = useRef(null)

  useClickOut(ref, open, () => {
    setOpen(false)
    ref.current.blur()
  })

  return (
    <div ref={ref}>
      <HoverLink
        open={open}
        onClick={e => setOpen(!open)}
        showTriangle={showTriangle}
        includeArrow
      >{title}
      </HoverLink>
      <Fade
        className={classNames(styles.dropdown, {
          [className]: className
        })}
        show={open}
      >
        <div>
          {children}
        </div>
      </Fade>
    </div>
  )
}

export default Dropdown
