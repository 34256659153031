import { matchPath } from 'react-router-dom'
import routes from './routes'

export const getPageRoute = async path => {
  const route = routes.find(route => matchPath(path, route))
  const page = await Promise.resolve(route.component.load ? route.component.load() : route.component)
  return { route, page }
}

export default getPageRoute
