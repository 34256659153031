import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useMedia } from 'react-use'

const UserWayWidget = () => {
  const [isClient, setIsClient] = useState(false)
  const isMobile = useMedia('(max-width: 480px)', false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  // UserWay Accessibility Widget
  if (!CONFIG.userway.enabled) {
    return null
  }

  /* override default position */
  /* s.setAttribute("data-position", 3); */
  /* override default size (values: small, large) */
  /* s.setAttribute("data-size", "small"); */
  /* override default language (e.g., fr, de, es, he, nl, etc.) */
  /* s.setAttribute("data-language", "language"); */
  /* override color set via widget (e.g., #053f67) */
  /* s.setAttribute("data-color", "#053e67"); */
  /* override type set via widget (1=person, 2=chair, 3=eye, 4=text) */
  /* s.setAttribute("data-type", "1"); */
  /* s.setAttribute("data-statement_text:", "Our Accessibility Statement"); */
  /* s.setAttribute("data-statement_url", "http://www.example.com/accessibility")"; */
  /* override support on mobile devices */
  /* s.setAttribute("data-mobile", true); */
  /* set custom trigger action for accessibility menu */
  /* s.setAttribute("data-trigger", "triggerId") */

  return (
    <Helmet>
      {isClient && !isMobile && (
        <script
          src='https://cdn.userway.org/widget.js'
          data-account={CONFIG.userway.dataAccount}
          data-position={isMobile ? 3 : 5}
        />
      )}
    </Helmet>
  )
}

export default UserWayWidget
