import { useEffect } from 'react'

/**
 * A hook at that calls a function when clicked outside of el ref.
 * @param el - a react reference to an element to be closed when clicking out side of.
 * @param open - current state of whether its open or not.
 * @param fn - a function to call when clicking out side of.
 */
export default function useClickOut (el, open, fn) {
  const handleWindowClick = e => {
    if (!open) {
      return
    }

    if (!el.current) {
      return
    }

    if (!document.body.contains(e.target)) {
      return
    }

    if (e.target.isSameNode(el.current)) {
      return
    }

    if (el.current.contains(e.target)) {
      return
    }

    fn(e)
  }

  useEffect(() => {
    window.addEventListener('click', handleWindowClick)
    return () => window.removeEventListener('click', handleWindowClick)
  })
}
