import { useRef, useEffect, useCallback } from 'react'

export const useScroll = onScroll => {
  const ticked = useRef(true)
  const canceled = useRef(false)
  const lastKnownY = useRef(0)

  const handleScrollTick = useCallback(() => {
    onScroll({ lastKnownY: lastKnownY.current })

    lastKnownY.current = window.scrollY
    ticked.current = true
  }, [onScroll])

  const handleScroll = useCallback(() => {
    if (!ticked.current || canceled.current) {
      return
    }

    ticked.current = false
    window.requestAnimationFrame(handleScrollTick)
  }, [handleScrollTick])

  useEffect(() => {
    canceled.current = false
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
      canceled.current = true
    }
  }, [handleScroll])
}

export default useScroll
