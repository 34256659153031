import createActionConstants from 'lib/create-action-constants'

export const constants = createActionConstants('notifications', [
  'FETCH_NOTIFICATIONS',
  'FETCH_NOTIFICATIONS_SUCCESS',
  'FETCH_NOTIFICATIONS_FAILURE',

  'SET_NOTIFICATION',
  'SET_NOTIFICATION_SUCCESS',
  'SET_NOTIFICATION_FAILURE',

  'UPDATE_NOTIFICATION',
  'UPDATE_NOTIFICATION_SUCCESS',
  'UPDATE_NOTIFICATION_FAILURE',

  'DELETE_NOTIFICATIONS',
  'DELETE_NOTIFICATIONS_SUCCESS',
  'DELETE_NOTIFICATIONS_FAILURE',

  'FLUSH_NOTIFICATION_ERROR',

  'FETCH_NOTIFICATION_ITEMS',
  'FETCH_NOTIFICATION_ITEMS_SUCCESS',
  'FETCH_NOTIFICATION_ITEMS_FAILURE',

  'FETCH_DROP_DOWN_NOTIFICATION_ITEMS',
  'FETCH_DROP_DOWN_NOTIFICATION_ITEMS_SUCCESS',
  'FETCH_DROP_DOWN_NOTIFICATION_ITEMS_FAILURE',

  'SET_NOTIFICATION_ITEMS_STATUS',
  'SET_NOTIFICATION_ITEMS_STATUS_SUCCESS',
  'SET_NOTIFICATION_ITEMS_STATUS_FAILURE',

  'HANDLE_QUERY_CHANGE'
])

export const fetchNotifications = (payload) => ({
  type: constants.FETCH_NOTIFICATIONS,
  payload
})

export const setNotification = (payload) => ({
  type: constants.SET_NOTIFICATION,
  payload
})

export const updateNotification = (payload) => ({
  type: constants.UPDATE_NOTIFICATION,
  payload
})

export const deleteNotifications = ids => ({
  type: constants.DELETE_NOTIFICATIONS,
  payload: ids
})

export const flushNotificationError = () => ({
  type: constants.FLUSH_NOTIFICATION_ERROR
})

export const fetchNotificationItems = (payload) => ({
  type: constants.FETCH_NOTIFICATION_ITEMS,
  payload,
  meta: {
    queryUpdate: payload && {
      update: {
        notificationIDs: 'notifications.notificationItemsFilters.notificationIDs',
        startDate: 'notifications.notificationItemsFilters.time.startDate',
        endDate: 'notifications.notificationItemsFilters.time.endDate',
        read: 'notifications.notificationItemsFilters.status.read',
        new: 'notifications.notificationItemsFilters.status.new',
        archived: 'notifications.notificationItemsFilters.status.archived',
        citationStatements: 'notifications.notificationItemsFilters.notificationType.citationStatements',
        newPublications: 'notifications.notificationItemsFilters.notificationType.newPublications',
        page: 'notifications.notificationItemsPage'
      }
    }
  }
})

export const fetchDropDownNotificationItems = () => ({
  type: constants.FETCH_DROP_DOWN_NOTIFICATION_ITEMS
})

export const setNotificationItemsStatus = (payload) => ({
  type: constants.SET_NOTIFICATION_ITEMS_STATUS,
  payload
})

export const handleQueryChange = query => ({
  type: constants.HANDLE_QUERY_CHANGE,
  payload: query
})
