export const generateRandomString = () => Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)

export const encodeHTMLEntities = (str) => {
  if (!str) {
    return str
  }
  return str.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;')
}

export const decodeHTMLEntities = (str) => {
  if (!str) {
    return str
  }
  return str.replace('&amp;', '&').replace('&lt;', '<').replace('&gt;', '>').replace('&quot;', '"')
}

const COUNTRY_CODE_MAP = {
  CN: 'China'
}
const TIMEZONES = {
  'Asia/Urumqi': {
    c: ['CN']
  },

  'Asia/Shanghai': {
    c: ['CN']
  },

  'Asia/Chongqing': {
    c: ['CN']
  },

  'Asia/Chungking': {
    c: ['CN']
  },

  PRC: {
    c: ['CN']
  }
}

/**
 * Returns a user's country based on their device's system time.
 * Right now we only care about Chinese users so only map those; in the future,
 * just expand the map above to include more regions / codes.
 * These are IANA tz identifiers
 * - https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
 */
export const getUserCountry = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  if (timezone === '' || !timezone || !(timezone in TIMEZONES)) {
    return null
  }

  const _countryCode = TIMEZONES[timezone].c[0] || null
  if (!_countryCode) {
    return null
  }

  const country = COUNTRY_CODE_MAP[_countryCode] || null
  return country
}

/**
 * Zotero Connector in the zotero browser extension
 * runs a regex to detect DOIs. If we load or change via JS
 * we need to tell it to re-run so it picks up the new DOIs.
 * See: https://forums.zotero.org/discussion/104696/zotero-connector-generic-translator?new=1
 */
export const forceZoteroUpdate = () => {
  try {
    // eslint-disable-next-line
    document.dispatchEvent(new Event('ZoteroItemUpdated', {
      bubbles: true,
      cancelable: true
    }))
  } catch (e) {}
}

export const removeQueryParamsFromUrl = (url) => {
  const urlObject = new URL(url)
  urlObject.search = ''
  return urlObject.toString()
}

export const valueIsSet = (val) => {
  return (typeof (val) !== 'undefined' && val !== null)
}

/**
 * Given an object, returns a new object with any null or undefined
 *  keys / values removed.
 * @param {*} extraData
 * @returns
 */
export const sanitizeObject = (extraData) => {
  if (!extraData) { return extraData }
  const sanitizedData = {}
  Object.keys(extraData).forEach(key => {
    if (extraData[key] !== null && extraData[key] !== undefined) {
      sanitizedData[key] = extraData[key]
    }
  })
  return sanitizedData
}
