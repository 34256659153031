import axios from 'axios'
import qs from 'querystring'
import { isExpired } from 'lib/token-utils'
import { getLocalStorage } from '../get-local-storage'

const IS_DEV = process.env.NODE_ENV === 'development'
const IS_SERVER = typeof window === 'undefined'
const extraHeaders = {}
//
// MILO:
//       In the interest of my RAM I run without Kong
//       in dev, so we can spoof our credentials...
//       Note also the API won't mint a token without
//       verifying the Kong consumer exists so you have
//       to set one manually in localStorage (at 'apiToken')
//
if (IS_DEV) {
  extraHeaders['x-consumer-id'] = 'FAKE BOY'
  extraHeaders['x-consumer-name'] = 'scitedevapi'
}

const localStorage = getLocalStorage()

const getUrl = () => {
  if (IS_DEV && !IS_SERVER) {
    return `http://${window.location.hostname}:8000`
  }

  return CONFIG.apiURL
}

export const api = axios.create({
  baseURL: getUrl(),
  paramsSerializer: params => qs.stringify(params),
  headers: {
    Authorization: {
      toString () {
        //
        // MILO: This is kind of a hack and I apologize
        //       but seems less likely to accidentally leak the secret
        //       into the client bundle than using eg config.get()
        //
        if (IS_SERVER) {
          return `Bearer ${global.SCITE_API_JWT_TOKEN_BACKEND}`
        }
        return `Bearer ${localStorage?.getItem('apiToken')}`
      }
    },
    ...extraHeaders
  }
})

export const webApi = axios.create({
  baseURL: IS_SERVER ? 'http://localhost:3000/api' : '/api',
  headers: {
    Authorization: {
      toString () {
        if (IS_SERVER) {
          return undefined
        }
        return `Bearer ${localStorage?.getItem('apiToken')}`
      }
    },
    ...extraHeaders
  }
})

let tokenRefreshing = null

//
// Check before we send a request if we need to refresh the
// user's API token + refresh it if we do.
//
api.interceptors.request.use(
  async (config) => {
    if (IS_SERVER) {
      return config
    }

    const apiToken = localStorage?.getItem('apiToken')

    if (!apiToken) {
      return config
    }

    if (isExpired(apiToken)) {
      if (!tokenRefreshing) {
        tokenRefreshing = webApi.get('/auth/api_token')
      }

      try {
        const { data: newToken } = await tokenRefreshing
        localStorage?.setItem('apiToken', newToken)
        tokenRefreshing = null
      } catch (e) {
        localStorage?.removeItem('apiToken')
        tokenRefreshing = null
        throw e
      }
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default api
