import qs from 'query-string'
import toTitleCase from 'lib/title-caser'
import {
  CITATION_TYPE_FILTER,
  RETRACTION_STATUS_TO_FILTER, PAPER_TYPE_FILTER
} from 'store/search/statics'

const toSeperateWord = word => word.replace('_', ' ')

const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
const snakeToCamelCase = str => str.toLowerCase().replace(/[-_][a-z]/g, (group) => group.slice(-1).toUpperCase())

export const filterEmpty = params => Object.entries(params).reduce((acc, next) => {
  const [key, val] = next

  if (val === '' || val === null || val === undefined || val.length === 0) {
    return acc
  }

  acc[key] = val
  return acc
}, {})

const getRangeParams = (key, ranges) => {
  const params = {}
  const min = Number(ranges[key].min)
  const max = Number(ranges[key].max)

  if (!isNaN(min)) {
    params[`${key}_from`] = min
  }

  if (!isNaN(max)) {
    params[`${key}_to`] = max
  }

  return params
}

const renderAggregationFilter = (filter) => {
  let label = ''
  if (Object.values(RETRACTION_STATUS_TO_FILTER).includes(filter)) {
    label = Object.keys(RETRACTION_STATUS_TO_FILTER).find(key => RETRACTION_STATUS_TO_FILTER[key] === filter)
  }

  if (Object.values(PAPER_TYPE_FILTER).includes(filter)) {
    label = Object.keys(PAPER_TYPE_FILTER).find(key => PAPER_TYPE_FILTER[key] === filter)
  }

  if (Object.values(CITATION_TYPE_FILTER).includes(filter)) {
    label = Object.keys(CITATION_TYPE_FILTER).find(key => CITATION_TYPE_FILTER[key] === filter)
  }

  if (label && !label.includes('Has')) {
    label = `Is ${label}`
  }
  return label
}

const renderCitationRangeFilter = (classificationType, value) => {
  let label = ''

  if (value && classificationType.includes('_from')) {
    label += `${toTitleCase(classificationType.replace('_from', ''))} > ${value}`
  }
  if (value && classificationType.includes('_to')) {
    label += `${toTitleCase(classificationType.replace('_to', ''))} < ${value}`
  }

  return label
}

/**
 * Takes a searchParams dict and renders a human-readable string outlining the parameters.
 * Used in various Saved Search flows.
 * @param {any} searchParams
 */
export const generateReadableSearchParams = (searchParams) => {
  if (!searchParams) {
    return '-'
  }
  let str = `Search term: ${searchParams.term ? searchParams.term : '--'}`

  if (searchParams.sort) {
    str += `, Sorted by: ${searchParams.sort} (${searchParams.sort_order})`
  }

  const ADVANCED_SEARCH_FILTERS = ['year_from', 'year_to', 'title', 'journal', 'abstract']
  ADVANCED_SEARCH_FILTERS.forEach((advancedParam) => {
    if (searchParams[advancedParam]) {
      str += `, ${toTitleCase(toSeperateWord(advancedParam))}: ${searchParams[advancedParam]}`
    }
  })

  const CITATION_RANGE_FILTERS = ['contrasting_from', 'contrasting_to', 'mentioning_from', 'mentioning_to', 'supporting_from', 'supporting_to']
  CITATION_RANGE_FILTERS.forEach((classificationType) => {
    if (searchParams[classificationType]) {
      str += `, ${renderCitationRangeFilter(classificationType, searchParams[classificationType])}`
    }
  })

  const AGGREGATION_FILTERS = ['has_concern', 'has_erratum', 'has_retraction', 'has_withdrawn', 'has_correction']
  AGGREGATION_FILTERS.forEach((filter) => {
    if (searchParams[filter] && searchParams[filter] === 'true') {
      str += `, ${renderAggregationFilter(filter)}`
    }
  })

  const authors = new Set([
    // filter exact quotes so we can dedupe
    searchParams?.author?.replace(/"/g, ''),
    // due to parsing, authors can be a string or array.
    ...(Array.isArray(searchParams?.authors) ? searchParams?.authors : [searchParams?.authors])
  ])
  if (searchParams?.authors?.length > 0) {
    str += `, Authors: ${[...authors]?.filter(author => !!author)?.map(author => toTitleCase(author?.split('-').slice(0, -1).join(' '))).join(', ')}`
  }
  const affiliations = new Set([
    // filter exact quotes so we can dedupe
    searchParams?.affiliation?.replace(/"/g, ''),
    // due to parsing, affiliations can be a string or array.
    ...(Array.isArray(searchParams?.affiliations) ? searchParams?.affiliations : [searchParams?.affiliations])
  ])
  if (searchParams?.affiliations?.length > 0) {
    str += `, Affiliations: ${[...affiliations]?.filter(affiliation => !!affiliation)?.map(affiliation => toTitleCase(affiliation?.split('-').slice(0, -1).join(' '))).join(', ')}`
  }

  const CITATION_STATEMENT_FILTERS = ['citation_type']
  CITATION_STATEMENT_FILTERS.forEach((advancedParam) => {
    if (searchParams[advancedParam]) {
      str += `, ${toTitleCase(toSeperateWord(advancedParam))}: ${searchParams[advancedParam]}`
    }
  })

  if (searchParams?.sections?.length > 0) {
    str += `, Sections: ${[...searchParams?.sections].join(', ')}`
  }

  return str
}

/**
 * Query string true/false to bool */
export const queryStringBool = (query, key, defaultValue = true) => {
  if (query[key] === 'true') {
    return true
  }

  if (query[key] === 'false') {
    return false
  }

  return defaultValue
}

export const parseRange = (query, key) => {
  const range = {}
  const minKey = `${key}From`
  const maxKey = `${key}To`
  if (minKey in query) {
    range.min = query[minKey]
  }

  if (maxKey in query) {
    range.max = query[maxKey]
  }

  return range
}

export const getApiSearchParams = ({ ui, search, search: { meshType, aggregationFilters } }) => {
  const params = {
    mode: search.mode,
    term: ui.search.term,
    limit: search.maxResultsPerPage,
    citation_types: search.citationTypes || [],
    sort: search.sort.value.key,
    sort_order: search.sort.value.order,
    sections: search.sections,
    date_from: search.years.from,
    date_to: search.years.to,
    journal: search.journal,
    has_tally: search.hasTally,
    author: search.author,
    affiliation: search.affiliation,
    mesh_type: search.meshType,
    dashboard_slug: search.dashboardSlug,
    dois_updated_ts: search.dashboardDOIsUpdatedTs,

    offset: (search.currentPage - 1) * search.maxResultsPerPage,
    ...getRangeParams('supporting', search.citationRanges),
    ...getRangeParams('contrasting', search.citationRanges),
    ...getRangeParams('mentioning', search.citationRanges),
    authors: aggregationFilters.authors || [],
    affiliations: aggregationFilters.affiliations || [],
    topics: aggregationFilters.topics || [],
    substances: aggregationFilters.substances || [],
    paper_types: aggregationFilters.paperTypes || [],
    journals: aggregationFilters.journals || [],

    user_slug: ui?.user?.slug
  }

  for (const key of Object.values(RETRACTION_STATUS_TO_FILTER)) {
    const apiKey = camelToSnakeCase(key)
    params[apiKey] = aggregationFilters[key] || null
  }

  return filterEmpty(params)
}

export const getClientQuerystring = apiParams => {
  const clientParams = {}

  for (const [key, value] of Object.entries(apiParams)) {
    if (key === 'term') {
      clientParams.q = value
      continue
    }

    if (key === 'sort') {
      clientParams.sortOn = value
      continue
    }

    if (key === 'date_from') {
      clientParams.yearFrom = value
      continue
    }

    if (key === 'date_to') {
      clientParams.yearTo = value
      continue
    }

    clientParams[snakeToCamelCase(key)] = value
  }

  return qs.stringify(clientParams, { arrayFormat: 'index' })
}
