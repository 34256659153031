import createReducer from 'lib/create-reducer'
import { constants } from './actions'
import saga from './sagas'

const defaultState = {
  id: '',
  name: '',
  displayName: '',
  normalizedName: '',
  description: '',
  slug: '',
  dashboardType: '',
  accessType: '',
  isPublic: null,
  componentLayout: [],
  doiQuery: {},
  journalMetadata: {},
  affiliationMetadata: {},
  institutionMetadata: {},
  haveAccessToDashboard: null,
  lastUpdated: null,
  doisUpdatedTS: null,
  doisRefreshedTS: null,
  refreshStatus: '',
  loadState: {},
  deleteDashboardError: false,
  deletingDashboard: false,
  totalDoiCount: 0,
  matchedDoiCount: 0,
  unmatchedDoiCount: 0,
  exportLoadTracker: {},
  setDashboardUsersLoading: false,
  setDashboardUsersError: false,
  setDashboardUsersSuccess: false,

  dashboardAccessList: null,
  getDashboardAccessListLoading: false,
  getDashboardAccessListSuccess: false,
  getDashboardAccessListError: false
}

const customDashboard = createReducer(defaultState, {
  [constants.FETCH_DASHBOARD]: (state, { type, slug }) => ({
    ...state,
    loadState: {
      state: 'loading',
      key: [type, slug].join('-')
    }
  }),
  [constants.FETCH_DASHBOARD_SUCCESS]: (state, { type, slug, data }) => ({
    ...state,
    ...data,
    loadState: {
      state: 'loaded',
      key: [type, slug].join('-')
    },
    haveAccessToDashboard: true
  }),
  [constants.FETCH_DASHBOARD_FAILURE]: state => ({
    ...state,
    loadState: { state: 'error' }
  }),
  [constants.DELETE_CUSTOM_DASHBOARD]: state => ({
    ...state,
    deletingDashboard: true
  }),
  [constants.DELETE_CUSTOM_DASHBOARD_SUCCESS]: state => ({
    ...state,
    deletingDashboard: false
  }),
  [constants.DELETE_CUSTOM_DASHBOARD_FAILURE]: state => ({
    ...state,
    deleteDashboardError: true,
    deletingDashboard: false
  }),
  [constants.FLUSH_DASHBOARD]: (state) => ({
    ...defaultState
  }),

  [constants.EXPORT_DASHBOARD]: (state, { dashboardSlug }) => ({
    ...state,
    exportLoadTracker: {
      state: 'loading',
      key: dashboardSlug
    }
  }),
  [constants.EXPORT_DASHBOARD_FAILURE]: (state, error) => ({
    ...state,
    exportLoadTracker: {
      state: 'error',
      error
    }
  }),
  [constants.EXPORT_DASHBOARD_SUCCESS]: state => ({
    ...state,
    exportLoadTracker: {
      ...state.exportLoadTracker,
      state: 'loaded'
    }
  }),
  [constants.GET_DASHBOARD_ACCESS_LIST]: state => ({
    ...state,
    getDashboardAccessListLoading: true,
    getDashboardAccessListSuccess: false,
    getDashboardAccessListError: false
  }),
  [constants.GET_DASHBOARD_ACCESS_LIST_SUCCESS]: (state, payload) => ({
    ...state,
    dashboardAccessList: payload,
    getDashboardAccessListLoading: false,
    getDashboardAccessListSuccess: true,
    getDashboardAccessListError: false
  }),
  [constants.GET_DASHBOARD_ACCESS_LIST_FAILURE]: state => ({
    ...state,
    getDashboardAccessListLoading: false,
    getDashboardAccessListSuccess: false,
    getDashboardAccessListError: true
  }),
  [constants.SET_DASHBOARD_USERS]: state => ({
    ...state,
    setDashboardUsersLoading: true,
    setDashboardUsersSuccess: false,
    setDashboardUsersError: false
  }),
  [constants.SET_DASHBOARD_USERS_SUCCESS]: state => ({
    ...state,
    setDashboardUsersLoading: false,
    setDashboardUsersSuccess: true,
    setDashboardUsersError: false
  }),
  [constants.SET_DASHBOARD_USERS_FAILURE]: state => ({
    ...state,
    setDashboardUsersLoading: false,
    setDashboardUsersSuccess: false,
    setDashboardUsersError: true
  }),
  [constants.CLEAR_SET_DASHBOARD_STATE]: state => ({
    ...state,
    setDashboardUsersLoading: false,
    setDashboardUsersSuccess: false,
    setDashboardUsersError: false
  })
}, saga)

export default customDashboard
