const PLANS = {
  free: 'free',
  premium: 'premium',
  organizational: 'organizational'
}

const SUBSCRIPTION_STATUS = {
  inactive: 'inactive',
  active: 'active',
  trialing: 'trialing',
  paymentFailed: 'payment_failed',
  paused: 'paused'
}

const STRIPE_STATUS_TO_SCITE_STATUS = {
  active: SUBSCRIPTION_STATUS.active,
  trialing: SUBSCRIPTION_STATUS.trialing,
  canceled: SUBSCRIPTION_STATUS.inactive
}

// These indicate a user has an account
// sometimes they will have an account from the past
// and are just signing in which is ok.
const SIGNUP_ACTIONS = {
  CREATED_ACCOUNT: 'Created an Account',

  LOGGED_IN_ORCID: 'Logged in - Orcid',
  SIGNED_UP_ORCID: 'Signed up - Orcid',

  LOGGED_IN_FACEBOOK: 'Logged in - Facebook',
  SIGNED_UP_FACEBOOK: 'Signed up - Facebook',

  LOGGED_IN_GOOGLE: 'Logged in - Google',
  SIGNED_UP_GOOGLE: 'Signed up - Google',

  LOGGED_IN_SAML_SSO: 'Logged in - Saml SSO',
  SIGNED_UP_SAML_SSO: 'Signed up - Saml SSO'
}

module.exports = {
  PLANS,
  SUBSCRIPTION_STATUS,
  STRIPE_STATUS_TO_SCITE_STATUS,
  SIGNUP_ACTIONS
}
