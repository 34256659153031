import { useState, useEffect } from 'react'

import { usePopper } from 'react-popper'
import classNames from 'classnames'
import Fade from 'components/misc/Fade'
import NavMenu from 'components/navbar/NavMenu'
import popperStyles from 'components/misc/styles/Tooltip.css'
import styles from './styles/NavDropdown.css'

const NavDropdown = ({
  className,
  dropdownClassName,
  buttonColor = '',
  children,
  items,
  menuOffset = [0, 0],
  initialShow = false,
  organizationLogo = null
}) => {
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const [arrowElement, setArrowElement] = useState(null)
  const [show, setShow] = useState(initialShow)

  const handleShowMenu = e => {
    setShow(!show)
  }
  const handleWindowClick = e => {
    //
    // This is so you can only open one menu at a time
    //
    if (e.target.isSameNode(referenceElement) || (referenceElement && referenceElement.contains(e.target))) {
      return
    }

    if (show) {
      setShow(false)
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleWindowClick)
    return () => window.removeEventListener('click', handleWindowClick)
  })

  useEffect(() => {
    if (initialShow) {
      setShow(initialShow)
    }
  }, [initialShow])

  const { styles: popperStyle, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowElement
        }
      },
      {
        name: 'offset',
        options: {
          offset: menuOffset
        }
      }
    ]
  })

  return (
    <div className={className}>
      <div
        className={classNames(styles.navDropdown, dropdownClassName, {
          [styles.whiteDropdown]: buttonColor === 'white'
        })}
        ref={setReferenceElement}
        onClick={handleShowMenu}
        tabIndex={0}
      >
        {children}
      </div>

      <Fade show={show}>
        <div
          className={classNames(styles.navMenuLayout, popperStyles.popperBase)}
          onClick={handleShowMenu}
          ref={setPopperElement}
          style={popperStyle.popper}
          {...attributes.popper}
        >
          <NavMenu items={items} organizationLogo={organizationLogo} />
          <div
            className={classNames(popperStyles.arrow, popperStyles.arrowWhite)}
            ref={setArrowElement}
            style={popperStyle.arrow}
          />
        </div>
      </Fade>
    </div>
  )
}

export default NavDropdown
