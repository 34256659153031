import jwtDecode from 'jwt-decode'

export const isExpired = token => {
  const payload = jwtDecode(token)

  //
  // Add ten second buffer to refresh token
  // up to ten seconds early
  //
  const epoch = Math.floor(Date.now() / 1000) + 10

  if (!payload.exp || payload.exp < epoch) {
    return true
  }

  return false
}
