import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import NavLogin from 'components/navbar/NavLogin'
import loadable from '@loadable/component'
import { toggleModal, MODAL_FROM_CONTEXTS } from 'store/ui/actions'
import styles from './styles/NavButtons.css'
import FeatureDropdown from './FeatureDropdown'
import ResourceDropdown from './ResourceDropdown'
import HoverLink from './HoverLink'
import classNames from 'classnames'
import OrganizationLogo from '../misc/OrganizationLogo'

const NavDropdown = loadable(() => import('components/navbar/NavDropdown'))
const FeedDropdown = loadable(() => import('components/navbar/FeedDropdown'))

export const NavButtons = ({ avatarBg = 'gray', buttonColor = 'transparent', staticPage, assistant = false }) => {
  const user = useSelector(({ ui: { user } }) => user)
  const navOpen = useSelector(({ ui: { navOpen } }) => navOpen)
  const dispatch = useDispatch()
  const [rendered, setRendered] = useState(false)
  const [renderOrgLogo, setRenderOrgLogo] = useState(false)

  useEffect(() => {
    setRendered(true)
  }, [])

  useEffect(() => {
    setRenderOrgLogo(!!user?.organizationLicense)
  }, [user])

  const toolsItems = [
    {
      name: 'Assistant',
      href: '/assistant',
      itemImg: `${CONFIG.assetsBaseURL}/images/icons/assistant.svg`
    },
    {
      name: 'Search',
      href: '/search/landing',
      itemImg: `${CONFIG.assetsBaseURL}/images/icons/feature-search.svg`
    },
    {
      name: 'Reference Check',
      onClick: () => dispatch(
        toggleModal(user?.email ? 'referenceCheck' : 'login', true, MODAL_FROM_CONTEXTS.REFERENCE_CHECK)
      ),
      itemImg: `${CONFIG.assetsBaseURL}/images/icons/feature-page.svg`
    },
    {
      name: 'Dashboards',
      href: '/users/me/dashboards',
      itemImg: `${CONFIG.assetsBaseURL}/images/icons/feature-dashboards.svg`
    },
    !user && {
      name: 'Plans & Pricing',
      href: '/pricing',
      itemImg: `${CONFIG.assetsBaseURL}/images/icons/pricing_icon.svg`
    },
    {
      name: 'Journals',
      href: '/journals',
      itemImg: `${CONFIG.assetsBaseURL}/images/icons/feature-journals.svg`
    },
    {
      name: 'Organizations',
      href: '/affiliations',
      itemImg: `${CONFIG.assetsBaseURL}/images/icons/organization_icon.svg`
    },
    {
      name: 'Funding Institutions',
      href: '/institutions',
      itemImg: `${CONFIG.assetsBaseURL}/images/icons/referrals.svg`
    },
    {
      name: 'Blog',
      href: 'https://scite.ai/blog',
      itemImg: `${CONFIG.assetsBaseURL}/images/icons/help_faq.svg`
    }
  ].filter(Boolean)

  return (
    <div className={styles.navButtons}>
      {rendered && (
        <NavDropdown
          className={styles.desktopHide}
          buttonColor={buttonColor}
          initialShow={navOpen}
          dropdownClassName={styles.linkItem}
          items={toolsItems}
          menuOffset={[-45, 26]}
          organizationLogo={user?.organizationLicense}
        >
          <i className={styles.menuIcon} />
        </NavDropdown>
      )}
      <>
        <div className={styles.mobileHide}>
          {!assistant && (<HoverLink to='/assistant'>Assistant</HoverLink>)}
          <FeatureDropdown showDropdownTriangle={false} />
          <ResourceDropdown showDropdownTriangle={false} />
          {!user && (
            <HoverLink to='/pricing'>Pricing</HoverLink>
          )}
          <HoverLink to='/blog'>Blog</HoverLink>
        </div>
        {user?.email && (
          <FeedDropdown />
        )}
        {renderOrgLogo && (
          <OrganizationLogo
            slug={user?.organizationLicense}
            styles={classNames(styles.orgLogo, styles.mobileHide)}
          />
        )}
      </>
      <NavLogin avatarBg={avatarBg} buttonColor={buttonColor} showSignup={staticPage} />
    </div>
  )
}

export default NavButtons
