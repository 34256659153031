import PropTypes from 'prop-types'
import { useSpring, useTrail, config, animated, useTransition } from 'react-spring'

export const Fade = ({
  show,
  delay,
  children,
  className,
  springConfig,
  style = {},
  fromOpacity = 0,
  onRest,
  ...rest
}) => {
  const transition = useTransition(show, {
    from: { opacity: fromOpacity },
    enter: { opacity: 1, pointerEvents: 'auto' },
    leave: { opacity: 0, pointerEvents: 'none' },
    delay,
    onRest
  })

  return transition(
    (props, show) =>
      show && (
        <animated.div className={className} style={{ ...style, ...props }} {...rest}>
          {children}
        </animated.div>
      )
  )
}

Fade.propTypes = {
  className: PropTypes.string,
  springConfig: PropTypes.object,
  style: PropTypes.object
}

export const FadeTrail = ({ items, springConfig, ...rest }) => {
  const trail = useTrail(items.length, {
    config: springConfig || config.default,
    from: { opacity: 0 },
    opacity: 1
  })
  return trail.map((style, index) => {
    const item = items[index]
    return (
      <animated.div key={items[index].key} style={style} {...rest}>
        <item.component {...item.componentProps} />
      </animated.div>
    )
  })
}

FadeTrail.propTypes = {
  items: PropTypes.array.isRequired,
  springConfig: PropTypes.object
}

export const FadeVisual = ({ className, children, show, onRest, ...rest }) => {
  const props = useSpring({
    opacity: show ? 1 : 0,
    pointerEvents: show ? 'auto' : 'none',
    onRest
  })

  return (
    <animated.div className={className} style={props} {...rest}>
      {children}
    </animated.div>
  )
}

FadeVisual.propTypes = {
  className: PropTypes.string
}

export default Fade
