import sleep from 'lib/sleep'
import Cookies from 'js-cookie'

// Only enable posthog if it's set in the config.
const CLIENT_SIDE = typeof window !== 'undefined'
const POSTHOG_ENABLED = (CLIENT_SIDE && CONFIG.posthog.enabled)
const POSTHOG_SESSION_REC_ENABLED = (CLIENT_SIDE && CONFIG.posthog.sessionRecordingEnabled)

let posthog = null

//
// Hack to wait for posthog to appear, since it is loaded asyncronously
// by routes/srr/App.js, whilst the user is loaded asyncronously by
// a saga.
//
// Ideally there would be some higher level saga that handled both I think.
//
export const waitForPosthog = async (interval = 1000, maxWait = 10000) => {
  let waited = 0

  while (waited <= maxWait) {
    if (posthog) {
      return true
    }

    await sleep(interval)
    waited += waited
  }

  console.warn('Posthog failed to load or took over 10 seconds')
  return false
}

const getBootstrap = () => {
  const anonId = Cookies.get('anonId')

  if (anonId) {
    return {
      distinctID: anonId,
      isIdentifiedID: false
    }
  }

  return {}
}

/**
 * Initialize posthog client
 */
export const initializePostHog = async (cookiePreference) => {
  const gpcValue = window.navigator.globalPrivacyControl

  if (!POSTHOG_ENABLED || gpcValue) {
    return
  }

  await sleep(5000)

  posthog = (await import('posthog-js')).default
  //
  // Config options here: https://posthog.com/docs/libraries/js#config
  //
  posthog.init(
    CONFIG.posthog.key,
    {
      api_host: CONFIG.posthog.apiHost,
      loaded: function () { console.log('Analytics loaded.') },
      disable_session_recording: true,
      opt_out_capturing_by_default: true,
      autocapture: false,
      capture_pageview: true,
      bootstrap: getBootstrap()
    }
  )
  if (cookiePreference === 'accepted' || cookiePreference.split(',').includes('targeting')) {
    posthog.opt_in_capturing()
  }
}

/**
 * Set the metadata object for a person. Useful for tracking, cohorts, etc.
 * Right now just use the user object from loadUser() call.
 * @param {*} user
 */
export const setPostHogPersonMetadata = async (user) => {
  if (!user) {
    return
  }

  if (!posthog) {
    const posthogLoaded = await waitForPosthog()

    if (!posthogLoaded) {
      return
    }
  }

  const isAdmin = (user?.admin || user?.email?.includes('scite.ai')) || false
  if (isAdmin) {
    // Don't capture admin behavior in events
    posthog.opt_out_capturing()
    return
  }

  if (user?.email) {
    identifyPostHogUserById(user.email)
  }

  posthog.people.set(
    {
      email: user.email,
      plan: user.subscription?.plan || 'free',
      subscriptionStatus: user.subscription?.status || 'inactive',
      admin: user.admin,
      expert: user.expert,
      orcid: user.orcid,
      signupType: user.signupType,
      organizationLicense: user.organizationLicense,
      persona: user.persona || user.userSpecifiedPersona || null
    }
  )
}

/**
 * Properly identify a user to avoid accidental duplication by
 * arbitrary unique IDs. If there are already dupes, this will alias
 * and automatically dedupe them.
 * @param {string} id
 */
export const identifyPostHogUserById = (id) => {
  if (!id || !posthog) {
    return
  }

  posthog.identify(id)
}

/**
 * Reset posthog. Call this when someone logs out. Important so it
 *    unsets any distinct_ids, which matters for users that share computers.
 * The optional resetDeviceId parameter can be used to reset the device ID.
 * @param {*} resetDeviceId
 */
export const resetPostHog = (resetDeviceId = false) => {
  if (!posthog) {
    return
  }

  posthog.reset(resetDeviceId)
}

/**
 * Opt user out of all posthog tracking.
 * Only runs if posthog is initialize because the library
 *   underneath will crash if not -_-. Bug in their code.
 */
export const optOutPostHogTracking = () => {
  if (!posthog) {
    return
  }

  posthog.opt_out_capturing()
}

/**
 * Opt user in of all posthog tracking.
 * Only runs if posthog is initialize because the library
 *   underneath will crash if not -_-. Bug in their code.
 */
export const optInPostHogTracking = () => {
  if (!posthog) {
    return
  }

  posthog.opt_in_capturing()
}

/**
 * Check if user has opted out of capturing
 * Only runs if posthog is initialize because the library
 *   underneath will crash if not -_-. Bug in their code.
 */
export const hasOptedOutCapturing = () => {
  if (!posthog) {
    return null
  }

  return posthog.has_opted_out_capturing()
}

/**
 * @param {Object} eventData Posthog event data
 * @param {string} eventData.name Event name (string)
 * @param {Object} [eventData.metadata] Metadata object, e.g. {property1: 'value', property2: 'another value'}
 */
export const capturePostHogEvent = (eventData) => {
  const hasOptedOut = hasOptedOutCapturing()
  if (!posthog || hasOptedOut) {
    return
  }

  try {
    posthog.capture(
      eventData.name,
      eventData.metadata
    )
  } catch (e) {
    console.error(e)
  }
}

/**
 * So, if someone is opted out of tracking, when they login, posthog won't sent their metadata
 *    because those are event calls.
 * This means that if opted out, it won't be able to set feature flags based on your property, since those
 *    are unset for opted out users.
 * Has an optional override (enableIfOptedOut) which, when set to true, will always return true if
 *    a user is opted out for a feature flag.
 * Has an optional override (enableForAdmin) which, when set to true, will always return true if
 *    a user is a admin.
 * NOTE: a user being an admin is something we track in the app, not in posthog, because all scite admins
 *    are opted out of posthog tracking.
 */
export const isFeatureFlagEnabled = async ({
  featureFlag,
  user = null,
  enableForAdmin = true,
  enableIfOptedOut = false
}) => {
  if (!posthog) {
    return
  }

  try {
    if (enableForAdmin && user?.admin) {
      return true
    }

    if (enableIfOptedOut && hasOptedOutCapturing()) {
      return true
    }

    // We need to wait for feature flags to be loaded
    await new Promise((resolve) => {
      posthog.onFeatureFlags((flags) => {
        resolve(flags)
      })
    })

    return posthog.isFeatureEnabled(featureFlag)
  } catch (e) {
    console.error(e)
  }
}

export const startPosthogSessionRecording = async () => {
  if (!posthog) {
    const posthogLoaded = await waitForPosthog()

    if (!posthogLoaded) {
      return
    }
  }

  if (!POSTHOG_SESSION_REC_ENABLED) {
    return
  }

  posthog.startSessionRecording()
}

export const stopPosthogSessionRecording = () => {
  if (!posthog) {
    return
  }

  if (!POSTHOG_SESSION_REC_ENABLED) {
    return
  }

  posthog.stopSessionRecording()
}
