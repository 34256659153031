/**
 * Function to freeze an object in development
 * to make it less likely I will mutate state
 * accidentally :) */
function deepFreeze (object) {
  const propNames = Object.getOwnPropertyNames(object)

  for (const name of propNames) {
    const value = object[name]

    if (value && typeof value === 'object') {
      deepFreeze(object[name])
    }
  }

  return Object.freeze(object)
}

export default function createReducer (initialState, handlers, saga) {
  if (process.env.NODE_ENV !== 'production') {
    deepFreeze(initialState)
  }

  function reducer (state = initialState, { type, payload, error } = {}) {
    if (process.env.NODE_ENV !== 'production') {
      deepFreeze(state)
    }

    if (Object.prototype.hasOwnProperty.call(handlers, type)) {
      return handlers[type](state, payload, error)
    } else {
      return state
    }
  }

  reducer.saga = saga
  return reducer
}
