/**
 * Highlights a chunk of text given a match string and optional tags.
 * @param {string} text - Text which has the match.
 * @param {string} match - The string to match and highlight.
 * @param {object} [tags] - startTag and endTag for enclosing the match.
 * @returns {string} - The text parameter that now has the match highlighted
 */
export const highlightText = (text, match, tags) => {
  try {
    if (!match || !text) {
      return text
    }

    const startTag = (tags && tags.startTag) || '<strong class="highlight">'
    const endTag = (tags && tags.endTag) || '</strong>'
    const trimmedMatch = match.trim()
    const escapedMatch = trimmedMatch.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    const regex = RegExp(escapedMatch, 'gi')
    // $& is a back reference in regex so that it will capture the match case.
    const replacement = `${startTag}$&${endTag}`
    return text.replace(regex, replacement)
  } catch (e) {
    console.error(e)
    return text
  }
}
