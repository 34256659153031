import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Slide from 'components/misc/Slide'
import { clearFilter } from 'store/search/actions'
import { selectSearchPills } from 'store/search/selectors'

import styles from './styles/SearchPills.css'

const Pill = ({ stateKey, label, value }) => {
  const dispatch = useDispatch()

  const [moused, setMoused] = useState(false)

  const handleMouseEnter = () => setMoused(true)
  const handleMouseLeave = () => setMoused(false)
  const handleClick = () => setMoused(!moused)

  return (
    <div className={styles.pill} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleClick}>
      <span className={styles.label}>
        {label}
      </span>
      <span className={styles.value}>
        {value}
        <Slide className={styles.close} show={moused} direction='right' onClick={() => dispatch(clearFilter(stateKey))}>
          <i className={styles.closeIcon} />
        </Slide>
      </span>
    </div>
  )
}

export const SearchPills = () => {
  const searchPills = useSelector(({ search }) => selectSearchPills(search))

  return (
    <div>
      {searchPills.map(pill => <Pill key={pill.stateKey} {...pill} />)}
    </div>
  )
}

export default SearchPills
